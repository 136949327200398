<div class="app-content">
    <app-side-bar></app-side-bar>

    <div class="body-content">
        <app-nav-bar></app-nav-bar>

        <div class="main-content">
            <router-outlet> </router-outlet>
        </div>
    </div>
</div>
