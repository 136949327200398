import { Component, OnInit } from '@angular/core';
import { APP_NAME } from '@helpers/app.constants';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = APP_NAME;
    constructor(private titleService: Title) { }
    ngOnInit(): void {
        this.titleService.setTitle(APP_NAME);
    }
}
