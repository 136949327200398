<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="inputContent"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.YEAR_ONLY" [ngTemplateOutlet]="yearOnly"></ng-container>
</ng-container>

<ng-template #default>
  <div class="form-group validated">
    <label class="form-control-label" [for]="name">{{ label | translate  }}</label>
    <ng-container [ngTemplateOutlet]="inputContent"></ng-container>
  </div>
</ng-template>

<ng-template #inline>
  <div class="form-group row validated">
    <label class="col-md-3 col-sm-12 input-inline-label form-control-label" [for]="name">{{ label | translate  }}</label>
    <div class="col-md-9 col-sm-12">
      <ng-container [ngTemplateOutlet]="inputContent"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #yearOnly>
  <div class="form-group validated">
    <label class="form-control-label" [for]="name">{{ label | translate  }}</label>
    <div class="input-group validated">
      <input
        matInput
        class="form-control"
        [ngClass]="{ 'is-invalid': isError }"
        [matDatepicker]="picker"
        (click)="picker.open()"
        [formControl]="form"
        [placeholder]="placeholder | translate"
        [name]="name"
        autocomplete="off"
      />
      <mat-datepicker
        #picker
        startView="multi-year"
        (yearSelected)="chosenYearHandler($event, picker)"
        panelClass="example-month-picker"
      >
      </mat-datepicker>
      <div class="input-group-append">
        <ng-container>
          <span class="input-group-text" [ngClass]="{ 'disable-active': this.isDiabled}">
            <mat-icon>calendar_today</mat-icon>
          </span>
        </ng-container>
      </div>
      <div class="invalid-feedback">{{ errorMessage | translate  }}</div>
    </div>
  </div>
</ng-template>

<ng-template #inputContent>
  <div class="input-group validated">
    <input
      matInput
      class="form-control"
      [ngClass]="{ 'is-invalid': isError }"
      [matDatepicker]="picker"
      (click)="picker.open()"
      [formControl]="form"
      [placeholder]="placeholder | translate"
      [name]="name"
      (keydown)="onkeyDown($event)"
      [min]="minDate"
      autocomplete="off"
    />
    <mat-datepicker #picker></mat-datepicker>
    <div class="input-group-append">
      <ng-container>
        <span class="input-group-text" [ngClass]="{ 'disable-active': this.isDiabled }">

          <mat-icon>calendar_today</mat-icon>
        </span>
      </ng-container>
    </div>
    <div class="invalid-feedback">{{ errorMessage | translate  }}</div>
  </div>
</ng-template>
