/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, forwardRef, ViewEncapsulation, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { distinctUntilChanged } from 'rxjs/operators';
import { LabelMode } from '../_enums/label-mode.enum';
import { GenerateRandom } from '../../../utils/rendom';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CheckboxComponent), multi: true }],
  encapsulation: ViewEncapsulation.None
})
export class CheckboxComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input()
  set error(value: any) {
    this.isError = false;
    this.errorMessage = '';

    if (value) {
      this.isError = true;
      const errorKey = Object.keys(value)[0];
      switch (errorKey) {
        case 'required':
          this.errorMessage = 'VALIDATOR.REQUIRED';
          return;
        default:
          this.errorMessage = value;
      }
    }
  }

  @Input() name = GenerateRandom(6);
  @Input() readonly = false;
  @Input() label = '';
  @Input() inputLabel = '';
  @Input()
  set labelMode(value: LabelMode) {
    this.mode = value;
  }

  @Input()
  set value(value: boolean) {
    this.form.patchValue(value)
  }

  LabelMode = LabelMode;
  mode: LabelMode = LabelMode.INLINE;

  form = new FormControl();
  subscription: Subscription = new Subscription();
  errorMessage = '';
  isRequired = false;
  isError = false;

  onChange = (value: string) => {
    return value;
  };
  onTouched = (value: string) => {
    return value;
  };

  @Output() valueChange = new EventEmitter<string>();

  ngOnInit() {
    this.subscription = this.form.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      const value = this.form.value;
      this.onChange(value);
      this.valueChange.emit(value);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  writeValue(value: any): void {
    this.form.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    return isDisabled;
  }
}
