import { Pipe, PipeTransform } from '@angular/core';
import { RetailerService } from '@core/services/api/retailer.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'displayReject',
})
export class DisplayRejectName implements PipeTransform {
    constructor(private retailerService: RetailerService) {}

    transform(categoryId: number): Observable<string> {
        return this.retailerService.getRejectCategory().pipe(
            map((res) => {
                const rejectCategoryList = res;
                const selectedRejectCategory = rejectCategoryList.find(
                    (category) => category.id === categoryId,
                );
                return selectedRejectCategory
                    ? selectedRejectCategory.description
                    : '';
            }),
        );
    }
}
