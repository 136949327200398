import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class SweetAlert2Service {
    constructor(
        private translate: TranslateService,
    ) {}
    swlOnCreate(message?: string) {
        const promise = new Promise<void>((resolve) => {
            Swal.fire({
                title: 'แน่ใจหรือไม่ ?',
                text: message || 'You want to create this user',
                icon: 'info',
                showCancelButton: true,
                // showLoaderOnConfirm: true,
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.value) {
                    resolve();
                }
            });
        });
        return promise;
    }

    // Submit
    onSubmit(title?: string, text?: string): Promise<void> {
        return new Promise<void>((resolve) => {
            Swal.fire({
                title: title || 'แก้ไขข้อมูล!',
                text: text || 'คุณแน่ใจใช่ไหมว่าข้อมูลที่กรอกไว้ถูกต้อง?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#21C063',
                cancelButtonColor: '#D9534F',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก',
                customClass: {
                    confirmButton: 'swal-confirm-button-right',
                    cancelButton: 'swal-cancel-button-left',
                    popup: 'swal-popup-custom',
                },
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    resolve();
                }
            });
        });
    }

    onChangeTab(title?: string, text?: string): Promise<void> {
        return new Promise<void>((resolve) => {
            Swal.fire({
                title: title || 'แก้ไขข้อมูล!',
                text: text || 'คุณแน่ใจใช่ไหมว่าข้อมูลที่กรอกไว้ถูกต้อง?',
                showCancelButton: true,
                confirmButtonColor: '#21C063',
                cancelButtonColor: '#FFFFFF',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก',
                customClass: {
                    confirmButton: 'swal-confirm-button-right',
                    cancelButton: 'swal-cancel-button-left',
                    popup: 'swal-popup-custom',
                },
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    resolve();
                }
            });
            const style = document.createElement('style');
            style.innerHTML = `
                div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
                    border: 2px solid #21C063 ;
                    color: #21C063 !important
                }
            `;
            document.head.appendChild(style);
        });
    }

    onAddorder(title?: string, item?: string, text?: string): Promise<void> {
        return new Promise<void>((resolve) => {
            Swal.fire({
                title: title || 'แน่ใจหรือไม่ ?',
                text: text || 'ยืนยัน',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'ตกลง',
                cancelButtonText: 'ยกเลิก',
                confirmButtonColor: '#24A0ED',
                cancelButtonColor: '#D9534F',
            }).then((result) => {
                if (result.isConfirmed) {
                    resolve();
                }
            });
        });
    }

  onDeleteImg(title?: string, text?: string): Promise<void> {
    return new Promise<void>((resolve) => {
        Swal.fire({
            title: title || 'แก้ไขข้อมูล!',
            text: text || 'คุณแน่ใจใช่ไหมว่าข้อมูลที่กรอกไว้ถูกต้อง?',
            showCancelButton: true,
            confirmButtonColor: '#F43A29',
            cancelButtonColor: '#FFFFFF',
            confirmButtonText: 'ลบ',
            cancelButtonText: 'ยกเลิก',
            customClass: {
                confirmButton: 'swal-confirm-button-right',
                cancelButton: 'swal-cancel-button-left',
                popup: 'swal-popup-custom',
            },
            reverseButtons: true,

        }).then((result) => {
            if (result.isConfirmed) {
            resolve();
            }
        });
        const style = document.createElement('style');
        style.innerHTML = `
            div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
                border: 2px solid #21C063 ;
                color: #21C063 !important
            }
        `;
        document.head.appendChild(style);
    });
  }

    onLogOut(title?: string, item?: string, text?: string): Promise<void> {
        return new Promise<void>((resolve) => {
            Swal.fire({
                title: title || this.translate.instant('AUTH.LOGOUT.LOGOUT'),
                text: text || this.translate.instant('AUTH.LOGOUT.MESSAGE'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.translate.instant('AUTH.LOGOUT.CONFIRM'),
                cancelButtonText: this.translate.instant('AUTH.LOGOUT.CANCEL'),
                confirmButtonColor: '#24A0ED',
                cancelButtonColor: '#D9534F',
                reverseButtons: true,
                customClass: {
                    confirmButton: 'swal-confirm-button-right',
                    cancelButton: 'swal-cancel-button-left',
                    popup: 'swal-popup-custom',
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    resolve();
                }
            });
            const style = document.createElement('style');
            style.innerHTML = `
                div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
                    border: 2px solid #D9534F !important ;
                    color: #D9534F !important
                }
            `;
        });
    }

    // Success
    onSuccess(title?: string): Promise<void> {
        return new Promise<void>((resolve) => {
            Swal.fire({
                title: title || 'สำเร็จ',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
            }).then(() => {
                resolve();
            });
        });
    }

    // Fail
    onFail(title?: string): void {
        Swal.fire({
            title: title || 'ผิดพลาด!',
            icon: 'error',
            showConfirmButton: false,
            timer: 1500,
        });
    }
}
