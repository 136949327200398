// ภาษไทย
export const locale = {
    lang: 'th',
    data: {
        TRANSLATOR: {
            SELECT: 'Select your language',
        },

        LABEL_LIST: {
            NO_DATA: 'ไม่มีข้อมูล',
            VIEW_DETAIL: 'ดูรายละเอียด'
        },

        MODAL_IMAGE: {
            TITLE: 'ยืนยันการลบรูป',
            MESSAGE: 'คุณต้องการที่จะลบรูปภาพนี้ใช่หรือไม่ ?',
            CONFIRM: 'ลบ',
            CANCEL: 'ยกเลิก'
        },

        PAGINATOR: {
            FIRST_PAGE: 'หน้าแรก',
            ITEM_PER_PAGE: 'รายการต่อหน้า:',
            LAST_PAGE: 'หน้าสุดท้าย',
            NEXT_PAGE: 'หน้าถัดไป',
            PREVIOUS_PAGE: 'หน้าก่อนหน้า',
            FROM: 'จาก',
            ITEM: 'รายการ',
        },

        PRODUCT: {
            PRODUCT: 'สินค้า',
            BRAND_TITLE: 'แบรนด์',
            MANUFACTURER_TITLE: 'ผู้ผลิต',
            CATEGORY_TITLE: 'หมวดหมู่',
            CREATE_PRODUCT: 'เพิ่มสินค้า',
            VIEW_PRODUCT: 'รายละเอียดสินค้า',
            CREATE_BRAND: 'เพิ่มแบรนด์',
            CREATE_MANUFACTURER: 'เพิ่มผู้ผลิต',
        },

        VALIDATOR: {
            REQUIRED: 'กรุณากรอกข้อมูล',
            MIN: 'กรุณากรอกค่าที่มากกว่า',
            MAX: 'กรุณากรอกค่าที่น้อยกว่านี้',
            EMAIL: 'กรุณาตรวจสอบอีเมล',
            MUST_MATCH: 'รหัสผ่านไม่ตรงกัน',
            PATTERN: 'กรุณาระบุเป็นตัวอักษร (A-Z, a-z, ก-ฮ) เท่านั้น',
            MIN_LENGTH: 'จำนวนขั้นต่ำ',
            MAX_LENGTH: 'จำนวนไม่เกิน',
            LENGTH_VAR: 'ตัว',
            DUPLICATE: 'ข้อมูลซ้ำ',
            DUPLICATE_PHONE: 'หมายเลขโทรศัพท์มือถือนี้ถูกใช้ไปแล้ว',
            DUPLICATE_TIME: 'เวลาปิดซ้ำกับเวลาเปิด',
            SIZE_FILE: 'ไฟล์มีขนาดใหญ่เกินไป',
            TYPE_FILE: 'ประเภทไฟล์ไม่ถูกต้อง',
            UPLOAD_FILE: 'ไม่สามารถอัพโหลดรูปได้',
            REQUIRED_IMAGE: 'กรุณาใส่รูปภาพ',
            REQUIRED_CATEGORY: 'กรุณาเลือกหมวดหมู่สินค้า',
            LIMIT_MIN: 'กรุณาใส่รูปอย่างน้อย ',
            LIMIT_MAX: 'กรุณาใส่รูปไม่เกิน ',
            UNIT_IMAGE: ' รูป',
            DUPLICATE_EAN: 'EAN นี้ถูกใช้ไปแล้ว',
            DATE_MAX: 'กรุณาเลือกวันที่ที่มากกว่า',
            PROMOTION_PRODUCT: 'กรุณาเลือกสินค้า',
            PROMOTION_GIFT: 'กรุณาเลือกของแถม',
            PROMOTION_OVERLAPPING_RANGE: 'ไม่สามารถกรอกจำนวนที่มีอยู่ในเงื่อนไขอื่นได้',
            PROMOTION_INVALID_TIER_ORDER: 'ไม่สามารถกรอกจำนวนต่ำกว่าเงื่อนไขก่อนหน้าได้',
            PROMOTION_INVALID_GAP: 'จำนวนขั้นต่ำของขั้นบันไดนี้ต้องต่อเนื่องจากจำนวนสูงสุดของขั้นก่อนหน้า',
            PROMOTION_INVALID_RANGE: 'ไม่สามารถกรอกจำนวนขั้นต่ำสูงกว่าจำนวนสูงสุดได้',
            PROMOTION_MIN_EQUAL_MAX: 'ไม่สามารถกรอกจำนวนขั้นต่ำเท่ากับจำนวนสูงสุดได้',
            PROMOTION_INVALID_MAX: 'จำนวนสูงสุดต้องเป็นตัวเลขหรือ "ไม่จำกัด"',
            PROMOTION_INVALID_PRODUCT: 'เลือกสินค้าที่มีราคาเดียวกัน สำหรับเงื่อนไขโปรโมชันขั้นบันไดที่มีมากกว่า 1 ขั้น'
        },

        SIDE_BAR: {
            RETAILER: 'ร้านค้าปลีก',
            STOCKIST: 'ร้านค้าส่ง',
            PRODUCT: 'สินค้า',
            ORDER: 'คำสั่งซื้อ',
            COUPON: 'คูปอง',
            PROMOTION: 'โปรโมชัน',
            ADMIN: 'แอดมิน',
            BILLING: 'ค่าธรรมเนียม',
            GIFT: 'ของแถม',
            NOTIFICATION: 'การแจ้งเตือน',
            CMS: 'จัดการหน้าหลัก',
            REPORT: 'รายงาน',
        },

        RETAILER_TITLE: {
            TITLE_ADD: 'เพิ่มร้านค้าปลีก',
            TITLE_UPDATE: 'รายละเอียดร้านค้าปลีก',
        },

        RETAILER: {
            PENDING: 'รอดำเนินการ / ดราฟท์',
            APPROVE: 'อนุมัติ',
            REJECT: 'ปฏิเสธ',
            TITLE_REGISTRANT: 'ข้อมูลผู้ลงทะเบียน',
            TITLE_STATUS: 'สถานะ',
            STATUS: 'สถานะ*',
            PERSION_IN_CHERT: 'ชื่อของผู้รับผิดชอบ',
            INPUT_PERSION_IN_CHERT: 'ผู้รับผิดชอบ',
            NAME: 'ชื่อร้านค้า',
            FIRST_NAME: 'ชื่อ',
            INPUT_FIRST_NAME: 'ชื่อที่จดทะเบียน',
            LAST_NAME: 'นามสกุล',
            TAX_ID: 'เลขประจำตัวผู้เสียภาษีอากร',
            MOBILE_PHONE_NUMBER: 'หมายเลขโทรศัพท์มือถือ',
            SECONDARY_PHONE_NUMBER: 'หมายเลขโทรศัพท์ร้านค้า',
            EMAIL: 'อีเมล',
            TITLE_STORE: 'ข้อมูลร้านค้า',
            CUSTOMER_TYPE: 'ประเภทลูกค้า',
            STORE_TYPE: 'ประเภทร้านค้า',
            OPEN_HOURS: 'เวลาเปิดให้บริการ',
            HOUSE: 'ชั่วโมง',
            MIN: 'นาที',
            TO: 'ถึง',
            OPEN_DAY: 'วันที่เปิดให้บริการ',
            TITLE_ADDRESS: 'ที่อยู่สำหรับการจัดส่ง',
            POST_CODE: 'รหัสไปรษณีย์',
            SUB_DISTRICT: 'ตำบล',
            DISTRICT: 'อำเภอ',
            PROVINCE: 'จังหวัด',
            ADDRESS: 'ที่อยู่',
            INPUT_ADDRESS: 'ป้อนที่อยู่',
            SEARCH_LOCATION: 'ค้นหาตำแหน่ง',
            CURRENT_LOCATION: 'ตำแหน่งปัจจุบัน',
            TITLE_BILLING_ADDRESS: 'ที่อยู่สำหรับออกเอกสารทางการเงิน',
            USE_ADDRESS: 'ใช้ที่อยู่เดียวกันกับที่อยู่จัดส่ง',
            BILLING_POST_CODE: 'รหัสไปรษณีย์',
            BILLING_SUB_DISTRICT: 'ตำบล',
            BILLING_DISTRICT: 'อำเภอ',
            BILLING_PROVINCE: 'จังหวัด',
            BILLING_ADDRESS: 'ที่อยู่',
            INPUT_BILLING_ADDRESS: 'ป้อนที่อยู่',
            TITLE_DOCUMENT: 'เอกสารเพิ่มเติม',
            ID_CARD: 'บัตรประจำตัวประชาชน (ด้านหน้า)',
            LIQUOR_LICENSE: 'ใบอนุญาตขายสุรา',
            TOBACCO_LICENSE: 'ใบอนุญาตขายบุหรี่',
            STORE_PICTURE: 'รูปภาพหน้าร้าน',
            POR_POR_20: 'ใบ ภ.พ.20',
            ETC: 'อื่นๆ',
            CREATE_DATE: 'วันที่ลงทะเบียน',
            UPDATE_DATE: 'อัปเดตล่าสุด',
            REJECT_CATEGORY: 'ประเภทการปฏิเสธ',
            REJECT_SUBCATEGORY: 'แรงจูงใจ',
            REJECT_REMARK: 'แสดงความคิดเห็น',
            APPROVE_CATEGORY: 'เหตุผลการยืนยัน',
            APPROVE_REMARK: 'แสดงความคิดเห็น',
            TITLE_REJECT: 'ยืนยันไม่อนุมัติร้านค้า',
            DETAIL_REJECT: 'หากหลังจากปฏิเสธ แล้วคุณต้องการเปิดใช้งานอีกครั้ง คุณสามารถทำได้จากผู้ดูแลร้านค้า',
            TITLE_APPROVE: 'ยืนยันอนุมัติร้านค้า',
            DETAIL_APPROVE: 'หากหลังจากอนุมัติ แล้วคุณต้องการปิดใช้งานอีกครั้ง คุณสามารถทำได้จากผู้ดูแลร้านค้า'
        },

        STOCKIST_TITLE: {
            TITLE_ADD: 'เพิ่มร้านค้าส่ง',
            TITLE_UPDATE: 'รายละเอียดร้านค้าส่ง',
        },

        STOCKIST: {
            TITLE_REGISTRANT: 'ข้อมูลการสร้างบัญชี',
            TITLE_REGIST: 'ข้อมูลผู้ลงทะเบียน',
            TITLE_STATUS: 'สถานะ',
            STATUS: 'สถานะ*',
            LOGO: 'โลโก้ร้านค้า',
            NAME: 'ชื่อร้านค้า',
            FIRST_NAME: 'ชื่อ',
            INPUT_FIRST_NAME: 'ชื่อที่จดทะเบียน',
            LAST_NAME: 'นามสกุล',
            TAX_ID: 'เลขประจำตัวผู้เสียภาษีอากร',
            MOBILE_PHONE_NUMBER: 'หมายเลขโทรศัพท์มือถือ',
            EMAIL: 'อีเมล',
            errorTextValidateEmail: 'อีเมลนี้ถูกใช้ไปแล้ว',
            errorTextValidatePhone: 'หมายเลขโทรศัพท์มือถือนี้ถูกใช้ไปแล้ว',
            BANK_ACCOUNT_NAME: 'ชื่อบัญชีรับเงิน',
            BANK_ACCOUNT_CODE: 'ธนาคารบัญชีรับเงิน',
            BANK_ACCOUNT_NO: 'เลขที่บัญชีรับเงิน',
            OPEN_DAY: 'วันที่เปิดให้บริการ',
            TITLE_ADDRESS: 'ที่ตั้งร้านค้า',
            POST_CODE: 'รหัสไปรษณีย์',
            SUB_DISTRICT: 'ตำบล',
            DISTRICT: 'อำเภอ',
            PROVINCE: 'จังหวัด',
            ADDRESS: 'ที่อยู่',
            INPUT_ADDRESS: 'ป้อนที่อยู่',
            SEARCH_LOCATION: 'ค้นหาตำแหน่ง',
            CURRENT_LOCATION: 'ตำแหน่งปัจจุบัน',
            TITLE_BILLING_ADDRESS: 'ที่อยู่สำหรับการเรียกเก็บเงิน',
            USE_ADDRESS: 'ใช้ที่อยู่เดียวกับที่ตั้งร้านค้า',
            BILLING_POST_CODE: 'รหัสไปรษณีย์',
            BILLING_SUB_DISTRICT: 'ตำบล',
            BILLING_DISTRICT: 'อำเภอ',
            BILLING_PROVINCE: 'จังหวัด',
            BILLING_ADDRESS: 'ที่อยู่',
            INPUT_BILLING_ADDRESS: 'ป้อนที่อยู่',
            TITLE_DOCUMENT: 'เอกสารเพิ่มเติม',
            ID_CARD: 'บัตรประจำตัวประชาชน (ด้านหน้า)',
            LIQUOR_LICENSE: 'ใบอนุญาตขายสุรา',
            TOBACCO_LICENSE: 'ใบอนุญาตขายบุหรี่',
            STORE_PICTURE: 'รูปภาพหน้าร้าน',
            POR_POR_20: 'ใบ ภ.พ.20',
            ETC: 'อื่นๆ',
            CREATE_DATE: 'วันที่ลงทะเบียน',
            UPDATE_DATE: 'อัปเดตล่าสุด',
            REGISTERED_NAME: 'ชื่อที่จดทะเบียน',
            MODAL_CREATE: {
                TITLE: 'การเพิ่มร้านค้าส่งสำเร็จ',
                SUBTITLE: 'ร้าน',
                MESSAGE: 'เพิ่มร้านค้าส่งสำเร็จแล้ว\nต้องการใส่ข้อมูลร้านค้าเพิ่มเติมตอนนี้หรือไม่?',
                CANCEL_BUTTON: 'กลับไปหน้าหลัก',
                CONFIRM: 'ใช่'
            }
        },

        RETAILER_HINT: {
            TITLE_REGISTRANT: 'ข้อมูลผู้ลงทะเบียน',
            NAME: 'ชื่อร้านค้า',
            FIRST_NAME: 'ชื่อ',
            INPUT_FIRST_NAME: 'ชื่อที่จดทะเบียน',
            LAST_NAME: 'นามสกุล',
            TAX_ID: 'เลขประจำตัวผู้เสียภาษีอากร',
            MOBILE_PHONE_NUMBER: 'หมายเลขโทรศัพท์มือถือ',
            SECONDARY_PHONE_NUMBER: 'หมายเลขโทรศัพท์ร้านค้า',
            EMAIL: 'อีเมล',
            CUSTOMER_TYPE: 'ประเภทลูกค้า',
            STORE_TYPE: 'ประเภทร้านค้า',
            POST_CODE: 'รหัสไปรษณีย์',
            SUB_DISTRICT: 'ตำบล',
            DISTRICT: 'อำเภอ',
            PROVINCE: 'จังหวัด',
            ADDRESS: 'ที่อยู่',
            INPUT_ADDRESS: 'ที่อยู่',
            BILLING_POST_CODE: 'รหัสไปรษณีย์',
            BILLING_SUB_DISTRICT: 'ตำบล',
            BILLING_DISTRICT: 'อำเภอ',
            BILLING_PROVINCE: 'จังหวัด',
            INPUT_BILLING_ADDRESS: 'ที่อยู่',
            HOUSE: 'ชั่วโมง',
            MIN: 'นาที',
            REJECT_CATEGORY: 'ประเภท',
            REJECT_SUBCATEGORY: 'แรงจูงใจ',
        },

        STOCKIST_HINT: {
            TITLE_REGISTRANT: 'ข้อมูลผู้ลงทะเบียน',
            STATUS: 'สถานะ',
            NAME: 'ชื่อร้านค้า',
            FIRST_NAME: 'ชื่อ',
            INPUT_FIRST_NAME: 'ชื่อที่จดทะเบียน',
            LAST_NAME: 'นามสกุล',
            TAX_ID: 'เลขประจำตัวผู้เสียภาษีอากร',
            MOBILE_PHONE_NUMBER: 'หมายเลขโทรศัพท์มือถือ',
            EMAIL: 'อีเมล',
            POST_CODE: 'รหัสไปรษณีย์',
            SUB_DISTRICT: 'ตำบล',
            DISTRICT: 'อำเภอ',
            PROVINCE: 'จังหวัด',
            ADDRESS: 'ที่อยู่',
            INPUT_ADDRESS: 'ที่อยู่',
            BILLING_POST_CODE: 'รหัสไปรษณีย์',
            BILLING_SUB_DISTRICT: 'ตำบล',
            BILLING_DISTRICT: 'อำเภอ',
            BILLING_PROVINCE: 'จังหวัด',
            BILLING_ADDRESS: 'ที่อยู่',
            BANK_ACCOUNT_NAME: 'ชื่อบัญชีรับเงิน',
            BANK_ACCOUNT_CODE: 'ธนาคารบัญชีรับเงิน',
            BANK_ACCOUNT_NO: 'เลขที่บัญชีรับเงิน',
            REGISTERED_NAME: 'ชื่อที่จดทะเบียน',
        },

        LIST_RETAILER: {
            RETAILER: 'ร้านค้าปลีก',
            ADD_RETAILER: 'เพิ่มร้านค้าปลีก',
            NAME: 'ชื่อร้านค้า',
            ADDRESS: 'ที่อยู่',
            MOBILEPHONENUMBER: 'หมายเลขโทรศัพท์มือถือ',
            EMAIL: 'อีเมล',
            CREATE_DATE: 'วันที่ลงทะเบียน',
            PERSON_CHARGE: 'ผู้รับผิดชอบ',
        },

        LIST_STOCKIST: {
            STOCKIST: 'ร้านค้าส่ง',
            ADD_STOCKIST: 'เพิ่มร้านค้าส่ง',
            NAME: 'ชื่อร้านค้า',
            ADDRESS: 'ที่อยู่',
            MOBILEPHONENUMBER: 'หมายเลขโทรศัพท์มือถือ',
            EMAIL: 'อีเมล',
            DELIVERY_ZONE: 'พื้นที่การจัดส่ง',
            TAX_ID: 'เลขประจำตัวผู้เสียภาษีอากร',
            ACTIVATE: 'เปิดการใช้งาน',
            DEACTIVATE: 'ปิดการใช้งาน',
            ALL: 'ทั้งหมด',
            MODAL_TITTLE: 'ยืนยันการปิดการใช้งาน',
            MODAL_SUBTITTLE: 'ร้าน',
            MODAL_MESSAGE: 'หากคุณปิดการใช้งาน' + '\n' + 'ซัพพลายเออร์จะไม่สามารถเข้าถึงบัญชีร้านค้าได้',
            MODAL_CONFIRM_LABEL: 'ปิดการใช้งาน',
            MODAL_CANCEL_LABEL: 'ยกเลิก',
            NO_ZONE: 'ไม่มีโซน',
            ZONE: 'โซน'
        },

        LIST_PRODUCT: {
            PRODUCT: 'สินค้า',
            ADD_PRODUCT: 'เพิ่มสินค้า',
            UPLOAD_FILE: 'อัพโหลดไฟล์',
            NAME: 'ชื่อสินค้า',
            ADDRESS: 'ที่อยู่',
            MOBILEPHONENUMBER: 'หมายเลขโทรศัพท์มือถือ',
            EMAIL: 'อีเมล',
            DELIVERY_ZONE: 'พื้นที่การจัดส่ง',
            TAX_ID: 'เลขประจำตัวผู้เสียภาษีอากร',
            ACTIVATE: 'เปิดการใช้งาน',
            DEACTIVATE: 'ปิดการใช้งาน',
            ALL: 'ทั้งหมด',
            COVERIMAGE: 'รูปสินค้า',
            BRANDNAME: 'ชื่อแบรนด์',
            CATEGORY: 'หมวดหมู่',
            MODAL_TITTLE: 'ยืนยันการปิดใช้งานสินค้า',
            MODAL_MESSAGE: 'คุณต้องการที่จะปิดการใช้งานสินค้านี้ใช่หรือไม่ ?',
            MODAL_CONFIRM: 'ปิดการใช้งาน',
            MODAL_CANCEL: 'ยกเลิก'
        },

        LIST_SHELF_MANAGEMENT: {
            PRODUCT: 'สินค้า',
            ADD_PRODUCT: 'เพิ่มสินค้า',
            NAME: 'ชื่อสินค้า',
            ADDRESS: 'ที่อยู่',
            MOBILEPHONENUMBER: 'หมายเลขโทรศัพท์มือถือ',
            EMAIL: 'อีเมล',
            DELIVERY_ZONE: 'พื้นที่การจัดส่ง',
            TAX_ID: 'เลขประจำตัวผู้เสียภาษีอากร',
            ACTIVATE: 'เปิดการใช้งาน',
            DEACTIVATE: 'ปิดการใช้งาน',
            ALL: 'ทั้งหมด',
            COVERIMAGE: 'รูปสินค้า',
            BRANDNAME: 'ชื่อแบรนด์',
            CATEGORY: 'หมวดหมู่',
            UNIT_PRICE: 'ราคาต่อหน่วย',
            MODAL_TITTLE: 'ยืนยันปิดการใช้งานสินค้า',
            MODAL_MESSAGE: 'คุณต้องการที่จะปิดการใช้งานสินค้านี้ใช่หรือไม่ ?',
            MODAL_CONFIRM_LABEL: 'ปิดการใช้งาน',
            MODAL_CANCEL_LABEL: 'ยกเลิก'
        },

        SHELF_MANAGEMENT: {
            UNIT_PRICE: 'ราคาต่อหน่วย',
            MINIMUM_UNIT: 'จำนวนขั้นต่ำ',
            MAXIMUM_UNIT: 'จำนวนสูงสุด',
            DOWNLOAD_FILE: 'ดาวน์โหลดไฟล์',
            UPLOAD_FILE: 'อัพโหลดไฟล์',
            UPLOAD_FILE_SUCCESS: 'อัพโหลดไฟล์เสร็จสิ้น',
            TITLE: 'แก้ไขรายละเอียดสินค้า',
            VIEW_TITLE: 'รายละเอียดสินค้า',
            ADD_FILE: 'เพิ่มไฟล์',
            FILE_DETAIL: 'กรุณาอัพโหลดไฟล์ xls,xlsx ขนาดไม่เกิน 100 MB',
            ERROR_FILE_TYPE: 'รูปแบบไฟล์ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
            ERROR_FILE_SIZE: 'ขนาดไฟล์เกินที่กำหนด กรุณาลองใหม่อีกครั้ง',
            ERROR_UPLOAD: 'อัพโหลดไฟล์ไม่สำเร็จ กรุณาเช็คความถูกต้องและลองใหม่อีกครั้ง',
            ERROR_EAN: 'ตรวจพบ EAN ซ้ำ กรุณาเช็คความถูกต้องและลองใหม่อีกครั้ง',
        },

        RETAILER_STATUS: {
            PENDING: 'รอดำเนินการ',
            CONFIRMED: 'อนุมัติแล้ว',
            REJECTED: 'ถูกปฏิเสธ',
            ALL: 'ทั้งหมด',
        },

        RETAILER_TAB: {
            STORE_INFORMATION: 'ข้อมูลร้านค้า',
            STORE_DOCUMENT: 'เอกสารร้านค้า',
        },

        STOCKIST_TAB: {
            STORE_INFORMATION: 'ข้อมูลร้านค้า',
            STORE_DOCUMENT: 'เอกสารร้านค้า',
            STORE_DERIVERY_ZONE: 'พื้นที่การจัดส่ง',
            STORE_SHELF: 'ชั้นวางสินค้า',
            STORE_PROMOTION: 'โปรโมชัน',
        },


        AUTH: {
            GENERAL: {
                OR: 'Or',
                SUBMIT_BUTTON: 'Submit',
                NO_ACCOUNT: "Don't have an account?",
                SIGNUP_BUTTON: 'Sign Up',
                FORGOT_BUTTON: 'ลืมรหัสผ่าน?',
                BACK_BUTTON: 'Back',
                PRIVACY: 'Privacy',
                LEGAL: 'Legal',
                CONTACT: 'Contact',
            },
            LOGIN: {
                TITLE: 'Login Account',
                BUTTON: 'Sign In',
            },
            FORGOT: {
                TITLE: 'Forgotten Password?',
                DESC: 'Enter your email to reset your password',
                SUCCESS: 'Your account has been successfully reset.',
            },
            REGISTER: {
                TITLE: 'Sign Up',
                DESC: 'Enter your details to create your account',
                SUCCESS: 'Your account has been successfuly registered.',
            },
            INPUT: {
                EMAIL: 'Email',
                FULLNAME: 'Fullname',
                PASSWORD: 'Password',
                CONFIRM_PASSWORD: 'Confirm Password',
                USERNAME: 'Username',
            },
            VALIDATION: {
                INVALID: '{{name}} is not valid',
                REQUIRED: '{{name}} is required',
                MIN_LENGTH: '{{name}} minimum length is {{min}}',
                AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
                NOT_FOUND: 'The requested {{name}} is not found',
                INVALID_LOGIN: 'The login detail is incorrect',
                REQUIRED_FIELD: 'Required field',
                MIN_LENGTH_FIELD: 'Minimum field length:',
                MAX_LENGTH_FIELD: 'Maximum field length:',
                INVALID_FIELD: 'Field is not valid',
            },

            LOGOUT: {
                LOGOUT: 'ออกจากระบบ',
                MESSAGE: 'แน่ใจหรือไม่ที่จะ ออกจากระบบ ?',
                CONFIRM: 'ตกลง',
                CANCEL: 'ยกเลิก',
            },
        },

        ACTION: {
            SUBMIT: 'บันทึก',
            CANCEL: 'ยกเลิก',
            PLEASE_SELECT: 'กรุณาเลือก',
            BY: 'โดย',
            SEARCH: 'ค้นหา',
            ADD_FILE: 'เพิ่มไฟล์',
            APPROVE: 'ยืนยัน',
            REJECT: 'ปฏิเสธ',
            DELETE: 'ลบ',
            CONFIRM: 'ตกลง',
            TO: 'ถึง',
        },

        FORM_MODAL: {
            CANCEL: {
                TITLE: 'ข้อมูลยังไม่ถูกบันทึก',
                MESSAGE: 'คุณต้องการที่ออกจากหน้านี้ใช่ไหม?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ยืนยัน',
            },

            CANT_CREATE: {
                TITLE: 'ไม่สามารถสร้างคูปองได้',
                SUBTITLE_1: 'เนื่องจากมีคูปองในช่วงเวลาเดียวกันถูกสร้างไว้แล้ว',
                MESSAGE:
                    'คุณต้องการที่ออกจากหน้านี้ใช่ไหม?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ออก',
            },
        },

        MANUFACTURER: {
            TITTLE_VIEW_MANUFACTURER: 'รายละเอียดผู้ผลิต',
            TITTLE_ADD_MANUFACTURER: 'เพิ่มผู้ผลิต',
            TITTLE_EDIT_MANUFACTURER: 'แก้ไขรายละเอียดผู้ผลิต',
            DETAIL: 'รายละเอียด',
            ID: 'รหัสผู้ผลิต',
            NAME: 'ชื่อผู้ผลิตภาษาไทย',
            NAME_EN: 'ชื่อผู้ผลิตภาษาอังกฤษ',
            LOGO: 'โลโก้',
            TNTOWNER: 'ผู้ผลิตภายใต้ไทยน้ำทิพย์'
        },
        MANUFACTURER_TITLE: {
            TITLE_ADD: 'เพิ่มผู้ผลิต',
            TITLE_UPDATE: 'รายละเอียดผู้ผลิต',
        },
        MANUFACTURER_HINT: {
            ID: 'รหัสผู้ผลิต',
            NAME: 'ชื่อผู้ผลิต',
            NAME_EN: 'ชื่อผู้ผลิตภาษาอังกฤษ',
        },
        MANUFACTURER_MODAL: {
            CANT_DELETE: {
                TITLE: 'ไม่สามารถลบผู้ผลิตได้',
                MESSAGE:
                    'ยังคงมีสินค้าหรือแบรนด์อยู่ในผู้ผลิต\nหากคุณต้องการลบผู้ผลิต\nกรุณาลบสินค้าและแบรนด์ทั้งหมดออกจากผู้ผลิตนี้',
                CANCEL: 'ยกเลิก',
            },
            CANCEL: {
                TITLE: 'ข้อมูลยังไม่ถูกบันทึก',
                MESSAGE: 'คุณต้องการที่ออกจากหน้านี้ใช่ไหม?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ยืนยัน',
            },
            DELETE: {
                TITLE: 'ยืนยันการลบผู้ผลิต',
                SUBTITLE_1: 'ผู้ผลิต',
                SUBTITLE_2: 'รหัสผู้ผลิต',
                MESSAGE: 'คุณต้องการที่จะลบผู้ผลิตนี้ใช่หรือไม่ ?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ยืนยัน',
            },
        },
        LISTBRAND: {
            BRAND: 'แบรนด์',
            NAME: 'ชื่อ',
            BRANDID: 'รหัสแบรนด์',
            MANUFACTURER: 'ผู้ผลิต',
            LOGO: 'โลโก้แบรนด์',
            ADD_BRAND: 'เพิ่มแบรนด์',
            EDIT_BRAND: 'แก้ไขรายละเอียดแบรนด์',
            VIEW_BRAND: 'รายละเอียดแบรนด์',
            MODAL_TITTLE: 'ยืนยันการปิดการใช้งานแบรนด์',
            MODAL_MESSAGE: 'คุณต้องการที่จะปิดการใช้งานแบรนด์นี้ใช่หรือไม่ ?',
            MODAL_CONFIRM: 'ปิดการใช้งาน',
            MODAL_CANCEL: 'ยกเลิก'
        },
        BRAND_STATUS: {
            ACTIVATED: 'เปิดการใช้งาน',
            DISABLE: 'ปิดการใช้งาน',
            ALL: 'ทั้งหมด',
        },
        BRAND_FORM: {
            DETAIL: 'รายละเอียด',
            BRANDID: 'รหัสแบรนด์',
            ADD_MANUFACTURER: 'ผู้ผลิต',
            NAME: 'ชื่อแบรนด์ภาษาไทย',
            NAME_EN: 'ชื่อแบรนด์ภาษาอังกฤษ',
            LOGO: 'โลโก้แบรนด์',
            CANCEL: 'ยกเลิก',
            SAVE: 'บันทึก',
            DELETE: 'ลบ'
        },
        BRAND_HINT: {
            DETAIL: 'รายละเอียด',
            BRANDID: 'รหัสแบรนด์',
            ADD_MANUFACTURER: 'ผู้ผลิต',
            NAME: 'ชื่อแบรนด์',
            NAME_EN: 'ชื่อแบรนด์ภาษาอังกฤษ',
        },
        BRAND_MODAL: {
            CANT_DELETE: {
                TITLE: 'ไม่สามารถลบแบรนด์ได้',
                MESSAGE:
                    'ยังคงมีสินค้าอยู่ในแบรนด์\nหากคุณต้องการลบแบรนด์\nกรุณาลบสินค้าทั้งหมดออกจากแบรนด์นี้',
                CANCEL: 'ยกเลิก',
            },
            CANCEL: {
                TITLE: 'ข้อมูลยังไม่ถูกบันทึก',
                MESSAGE: 'คุณต้องการที่ออกจากหน้านี้ใช่ไหม?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ยืนยัน',
            },
            DELETE: {
                TITLE: 'ยืนยันการลบแบรนด์',
                SUBTITLE_1: 'แบรนด์',
                SUBTITLE_2: 'รหัสแบรนด์',
                MESSAGE: 'คุณต้องการที่จะลบแบรนด์นี้ใช่หรือไม่ ?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ลบ',
            },
        },
        LIST_MANUFACTURER: {
            MANUFACTURER: 'ผู้ผลิต',
            ADD_MANUFACTURER: 'เพิ่มผู้ผลิต',
            IMAGE: 'โลโก้ผู้ผลิต',
            LOGO: 'โลโก้ผู้ผลิต',
            MANUFACTURER_ID: 'รหัสผู้ผลิต',
            NAME: 'ชื่อ',
            ACTIVATE: 'เปิดการใช้งาน',
            DEACTIVATE: 'ปิดการใช้งาน',
            ALL: 'ทั้งหมด',
            MODAL_TITTLE: 'ยืนยันการปิดใช้งานผู้ผลิต',
            MODAL_MESSAGE: 'คุณต้องการที่จะปิดการใช้งานผู้ผลิตนี้ใช่หรือไม่ ?',
            MODAL_CONFIRM: 'ปิดการใช้งาน',
            MODAL_CANCEL: 'ยกเลิก'


        },

        PRODUCT_FORM: {
            NAME: 'ชื่อสินค้าภาษาไทย',
            NAMEEN: 'ชื่อสินค้าภาษาอังกฤษ',
            EAN: 'EAN',
            BRAND: 'แบรนด์',
            MANUFACTURER: 'ผู้ผลิต',
            DESCRIPTION_PRODUCT: 'คำอธิบายสินค้า',
            DESCRIPTION: 'คำอธิบายภาษาไทย',
            DESCRIPTIONEN: 'คำอธิบายภาษาอังกฤษ',
            CATEGORY: 'ชื่อหมวดหมู่สินค้า',
            EDIT_PRODUCT: 'แก้ไขรายละเอียดสินค้า',
            DETAIL_PRODUCT: 'รายละเอียดสินค้า',
            COVER_IMAGE: 'ภาพปก',
            PRODUCT_IMAGE: 'รูปสินค้าเพิ่มเติม',
            PRODUCT_IMAGE_COVER: 'ภาพสินค้า',
            CATEGORY_PRODUCT: 'หมวดหมู่สินค้า',
            ERROR: {
                NOT_FOUND: "ไม่พบข้อมูล"
            },
            LOADING: "กำลังโหลด..."
        },
        PRODUCT_HINT: {
            NAME: 'ชื่อสินค้า',
            NAMEEN: 'ชื่อสินค้าภาษาอังกฤษ',
            EAN: 'EAN',
            BRAND: 'แบรนด์',
            MANUFACTURER: 'ผู้ผลิต',
            DESCRIPTION: 'คำอธิบายภาษาไทย (ไม่บังคับ)',
            DESCRIPTIONEN: 'คำอธิบายภาษาอังกฤษ (ไม่บังคับ)',
            CATEGORY: 'หมวดหมู่สินค้า',
        },
        PRODUCT_MODAL: {
            CANCEL: {
                TITLE: 'ข้อมูลยังไม่ถูกบันทึก',
                MESSAGE: 'คุณต้องการที่ออกจากหน้านี้ใช่ไหม?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ยืนยัน',
            },
            DELETE: {
                TITLE: 'ยืนยันการลบสินค้า',
                SUBTITLE_1: 'สินค้า',
                SUBTITLE_2: 'EAN',
                MESSAGE: 'คุณต้องการที่จะลบสินค้านี้ใช่หรือไม่ ?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ลบ',
            },
        },
        ZONE: {
            LABEL_ZONE: 'พื้นที่การจัดส่ง',
            ADD_ZONE: 'เพิ่มพื้นที่จัดส่ง',
            BACK_BUTTON: 'กลับไปหน้าก่อนหน้า',
            ADD_ZONE_DELIVERY: 'กำหนดพื้นที่การจัดส่ง',
            ZONE_NAME: 'ชื่อพื้นที่จัดส่ง',
            ZONE_CONDITION: 'ข้อจำกัดการสั่งซื้อ',
            MININUM_FEE: 'ยอดสั่งซื้อขั้นต่ำ',
            DELIVERY_FEE: 'ค่าบริการจัดส่ง',
            ERROR_EMPTY_POLYGON: 'กรุณาวาดพื้นที่จัดส่ง Delivery Zone',
            ERROR_POLYGON: 'Delivery Zone ผิดพลาด กรุณาวาดพื้นที่จัดส่ง Delivery Zone อีกครั้ง',

        },
        MODAL_DELETE_ZONE: {
            HEADER: "ยืนยันการลบพื้นที่จัดส่ง",
            DESCRIPTION: "คุณต้องการที่จะลบพื้นที่จัดส่งนี้ใช่หรือไม่ ?",
            CONFRIM: "ลบ",
            CANCEL: "ยกเลิก"
        },
        HINT_ZONE: {
            ZONE_NAME: 'ชื่อพื้นที่จัดส่ง',
            MININUM_FEE: 'กรอกยอดคำสั่งซื้อขั้นต่ำ',
            DELIVERY_FEE: 'กรอกค่าบริการจัดส่ง',
        },

        CATEGORY: {
            LEVEL_1: 'ระดับ 1',
            LEVEL_2: 'ระดับ 2',
            LEVEL_3: 'ระดับ 3',
            ADD_CATEGORY: 'เพิ่มหมวดหมู่',
            CATEGORY_NUMBER: 'รหัสหมวดหมู่',
            CATEGORY_NAME: 'ชื่อภาษาไทย',
            CATEGORY_NAME_EN: 'ชื่อภาษาอังกฤษ',
            CATEGORY_REQUIRED: 'กรุณากรอกข้อมูล',
            NOTFOUND_CATEGORY: 'ไม่พบหมวดหมู่'
        },

        CATEGORY_MODAL: {
            CANT_DELETE: {
                TITLE: 'ไม่สามารถลบหมวดหมู่ได้',
                MESSAGE:
                    'ยังคงมีสินค้าอยู่ในหมวดหมู่\nหากคุณต้องการ ลบหมวดหมู่\nกรุณาลบสินค้าทั้งหมดออกจาก หมวดหมู่นี้',
                CANCEL: 'ยกเลิก',
            },
            CANT_CREATE: {
                TITLE: 'ไม่สามารถเพิ่มหมวดหมู่ย่อยได้',
                MESSAGE:
                    'ยังคงมีสินค้าอยู่ในหมวดหมู่\nหากคุณต้องการ เพิ่มหมวดหมู่ย่อย\nกรุณาลบสินค้าทั้งหมดออกจาก หมวดหมู่นี้',
                MESSAGE_2: 'ชื่อหมวดหมู่ซ้ำกับหมวดหมู่ที่มีอยู่แล้ว',
                CANCEL: 'ยกเลิก',
            },
            CANT_CREATE_SUB: {
                TITLE: 'ไม่สามารถเพิ่มหมวดหมู่ย่อยได้',
                MESSAGE:
                    'ยังคงมีสินค้าอยู่ในหมวดหมู่\nหากคุณต้องการ เพิ่มหมวดหมู่ย่อย\nกรุณาลบสินค้าทั้งหมดออกจาก หมวดหมู่นี้',
                CANCEL: 'ยกเลิก',
            },
            CANCEL: {
                TITLE: 'ข้อมูลยังไม่ถูกบันทึก',
                MESSAGE: 'คุณต้องการที่ออกจากหน้านี้ใช่ไหม?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ยืนยัน',
            },
            DELETE: {
                TITLE: 'ยืนยันการลบหมวดหมู่',
                SUBTITLE_1: 'หมวดหมู่',
                SUBTITLE_2: 'รหัสหมวดหมู่',
                MESSAGE: 'คุณต้องการที่จะลบหมวดหมู่นี้ใช่หรือไม่ ?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ยืนยัน',
            },
        },

        DAY: {
            MON: 'จ',
            TUE: 'อ',
            WED: 'พ',
            THU: 'พฤ',
            FRI: 'ศ',
            SAT: 'ส',
            SUN: 'อา',
        },

        ADMIN_STATUS: {
            ACTIVATED: 'เปิดการใช้งาน',
            DISABLE: 'ปิดการใช้งาน',
            ALL: 'ทั้งหมด',
        },

        LIST_ADMIN: {
            ADMIN: 'แอดมิน',
            ADD_ADMIN: 'เพิ่มแอดมิน',
            ADMIN_ID: 'รหัสแอดมิน',
            NAME: 'ชื่อ',
            LAST_NAME: 'นามสกุล',
            ROLE: 'หน้าที่',
            EMAIL: 'อีเมล',
            PHONE_NUMBER: 'หมายเลขโทรศัพท์มือถือ',
            ACTIVATE: 'เปิดการใช้งาน',
            DEACTIVATE: 'ปิดการใช้งาน',
            ALL: 'ทั้งหมด',
        },

        ADMIN: {
            TITTLE_ADD_ADMIN: 'เพิ่มแอดมิน',
            TITTLE_EDIT_ADMIN: 'แก้ไขรายละเอียดแอดมิน',
            TITTLE_VIEW_ADMIN: 'ดูรายละเอียดแอดมิน',
            TITLE_STATUS: 'สถานะ',
            STATUS: 'สถานะ',
            CREATE_DATE: 'วันที่ลงทะเบียน',
            UPDATE_DATE: 'อัปเดตล่าสุด',
            DETAIL: 'ข้อมูลผู้ลงทะเบียน',
            ID: 'รหัสแอดมิน',
            NAME: 'ชื่อ',
            LAST_NAME: 'นามสกุล',
            EMAIL: 'อีเมล',
            MOBILE_PHONE_NUMBER: 'หมายเลขโทรศัพท์มือถือ',
            TITLE_ROLE: 'สิทธิการเข้าถึง',
            ROLE: 'หน้าที่',
            NAME_EN: 'ชื่อแอดมินภาษาอังกฤษ',
            ACTIVATE: 'เปิดการใช้งาน',
            INACTIVATE: 'ปิดการใช้งาน',
            BY: 'โดย',
            errorTextValidateEmail: 'อีเมลนี้ถูกใช้ไปแล้ว',
            TITLE_PASSWORD: 'รหัสผ่าน',
            PASSWORD: 'รหัสผ่าน',
            CONFIRM_PASSWORD: 'ยืนยันรหัสผ่าน',
        },

        ADMIN_HINT: {
            TITTLE_ADD_ADMIN: 'เพิ่มแอดมิน',
            TITTLE_EDIT_ADMIN: 'แก้ไขรายละเอียดแอดมิน',
            TITLE_STATUS: 'สถานะ',
            STATUS: 'สถานะ',
            CREATE_DATE: 'วันที่ลงทะเบียน',
            UPDATE_DATE: 'อัปเดตล่าสุด',
            DETAIL: 'ข้อมูลผู้ลงทะเบียน',
            ID: 'รหัสแอดมิน',
            NAME: 'ชื่อ',
            LAST_NAME: 'นามสกุล',
            EMAIL: 'อีเมล',
            MOBILE_PHONE_NUMBER: 'หมายเลขโทรศัพท์มือถือ',
            TITLE_ROLE: 'สิทธิการเข้าถึง',
            ROLE: 'เลือกตำแหน่งหน้าที่',
            NAME_EN: 'ชื่อแอดมินภาษาอังกฤษ',
            PASSWORD: 'รหัสผ่าน',
            CONFIRM_PASSWORD: 'ยืนยันรหัสผ่าน',
        },
        ADMIN_MODAL: {
            CANCEL: {
                TITLE: 'ข้อมูลยังไม่ถูกบันทึก',
                MESSAGE: 'คุณต้องการที่ออกจากหน้านี้ใช่ไหม?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ยืนยัน',
            },
            DELETE: {
                TITLE: 'ยืนยันการลบแอดมิน',
                SUBTITLE_1: 'แอดมิน',
                SUBTITLE_2: 'รหัสแอดมิน',
                MESSAGE: 'คุณต้องการที่จะลบแอดมินนี้ใช่หรือไม่ ?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ยืนยัน',
            },
            CANT_CREATE: {
                TITLE: 'ไม่สามารถสร้างแอดมินได้',
                MESSAGE: 'อีเมลซ้ำกับผู้ใช้อื่น',
                CANCEL: 'ยกเลิก',
            },
            CLOSE: {
                TITLE: 'ยืนยันการปิดใช้งานแอดมิน',
                MESSAGE: 'คุณต้องการที่จะปิดการใช้งานแอดมินนี้ใช่หรือไม่ ?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ปิดการใช้งาน',
            },
        },
        COUPON: {
            VIEW: {
                TITLE: 'ดูรายละเอียดคูปอง',
            },
            CREATE: {
                TITLE: 'เพิ่มคูปอง',
                EDIT_TITLE: 'แก้ไขคูปอง',
                TITLE_DETAIL_COUPON: 'รายละเอียดคูปอง',
                TITLE_DETAIL_REDUCE_RATE: 'รูปแบบการลด',
                COUPON_CODE: 'รหัสคูปอง',
                HINT_COUPON_CODE: 'รหัสคูปอง',
                MINIMUM_PRICE: 'คำสั่งซื้อขั้นต่ำ(บาท)',
                HINT_MINIMUM_PRICE: 'คำสั่งซื้อขั้นต่ำ(บาท)',
                PERCENT: 'เปอร์เซ็นต์',
                CURRENCY: 'บาท(หน่วย)',
                DISSCOUNT: 'ส่วนลด',
                HINT_DISSCOUNT: 'ส่วนลด',
                HINT_DISSCOUNT_PERCENT: '%',
                LIMIT_DISSCOUNT_PERCENT: 'สูงสุดไม่เกิน',
                HINT_LIMIT_DISSCOUNT_PERCENT: '฿',
                HINT_DISSCOUNT_CURENCY: '฿',
                TITLE_CONDITION: 'เงื่อนไขการใช้งาน',
                START_DATE: 'ช่วงเวลาที่เปิดใช้งาน',
                END_DATE: 'ถึง',
                QOUTAPERRETAILER: 'จำนวนการใช้งานต่อผู้ใช้',
                HINT_QOUTAPERRETAILER: 'จำนวนการใช้งานต่อผู้ใช้',
                QOUTALIMIT: 'จำนวนการใช้งานทั้งหมด',
                HINT_QOUTALIMIT: 'จำนวนการใช้งานทั้งหมด',
                TITLE_RETAILERTYPE: 'กำหนดสิทธิ์การใช้งานของร้านค้าปลีก',
                TITLE_PRODUCTTYPE: 'กำหนดสินค้าที่เข้าร่วม',
                ALLTYPE: 'ทั้งหมด',
                EXCEPTTYPERETAILER: 'ยกเว้นร้านค้า',
                SPECIFICTYPERETAILER: 'เฉพาะร้านค้า',
                EXCEPTTYPEPRODUCT: 'ยกเว้นสินค้า',
                SPECIFICTYPEPRODUCT: 'ใช้ได้เฉพาะสินค้า',
                HINT_TIME: '00',
                HINT_DATE: 'YYYY-MM-DD',
                DOWNLOAD_FILE_EXAMPLE: 'ดาวน์โหลดตัวอย่างไฟล์',
                DOWNLOAD_FILE: 'ดาวน์โหลดไฟล์',
                ADD_FILE: 'เพิ่มไฟล์',
                HINT_ADD_FILE: 'กรุณาอัพโหลดไฟล์ csv ขนาดไม่เกิน 100 Mb',
                ERROR_FILE_TYPE: 'รูปแบบไฟล์ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
                ERROR_FILE_SIZE: 'ขนาดไฟล์เกินที่กำหนด กรุณาลองใหม่อีกครั้ง',
            },
            MODAL_DISABLE: {
                MODAL_TITTLE: "ยืนยันการปิดใช้งานคูปอง",
                MODAL_MESSAGE: "คุณต้องการที่จะปิดการใช้งานคูปองนี้ใช่หรือไม่ ?",
                MODAL_CONFIRM: "ปิดการใช้งาน",
                MODAL_CANCEL: "ยกเลิก"
            },
            DELETE: {
                TITLE: 'ยืนยันการลบคูปอง',
                MESSAGE: 'คุณต้องการที่จะลบคูปองนี้ใช่หรือไม่ ?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ลบ',
            }
        },
        LIST_COUPON: {
            TITLE: 'คูปอง',
            ADD_COUPON: 'เพิ่มคูปอง',
            ACTIVATE: 'เปิดการใช้งาน',
            DEACTIVATE: 'ปิดการใช้งาน',
            ALL: 'ทั้งหมด',
            CODE: 'รหัสคูปอง',
            DISCOUNT: 'ส่วนลด',
            TOTAL_DISCOUNT: 'ใช้ส่วนลดไปแล้วทั้งสิ้น',
            QUOTA_USED: 'จำนวนการใช้งาน',
            EFFECTIVE_DATE: 'ระยะเวลาที่มีผล',
            STATUS: 'สถานะ',
            STATUS_INACTIVE: 'ปิดการใช้งาน',
            STATUS_EXPIRE: 'หมดอายุ',
            STATUS_ACTIVE: 'ใช้ได้',
            STATUS_UP_COMING: 'ยังไม่เริ่มใช้งาน',
            TITLE_ERR_ACTIVE: 'ไม่สามารถเปิดใช้งานคูปองได้',
            MESSAGE_ERR_ACTIVE: 'เนื่องจากยังมีคูปองชื่อเดียวกันใช้งานอยู่',
            BTN_CANCEL: 'ยกเลิก'
        },
        ORDER_LIST: {
            ORDER_ID: 'หมายเลขคำสั่งซื้อ',
            STOCKIST_NAME: 'ชื่อร้านค้าส่ง',
            RETAILER_NAME: 'ชื่อร้านค้าปลีก',
            UPDATE_AT: 'อัพเดทล่าสุด',
            CREATE_AT: 'วันที่สั่งซื้อ',
            NET_AMOUNT: 'ยอดคำสั่งซื้อสุทธิ',
            COUPON_DISCOUNT: 'ส่วนลดคูปอง',
            TOTAL_AMOUNT: 'ยอดคำสั่งซื้อรวม',
            STATUS: 'สถานะ'
        },
        ORDER_STATUS: {
            ALL: 'ทั้งหมด',
            PENDING: 'รอยืนยัน',
            CONFIRMED: 'ยืนยันแล้ว',
            CANCELREQUESTED: 'ขอยกเลิก',
            CANCELLED: 'ยกเลิกแล้ว',
            DEPRECATED: 'เปลี่ยนแปลง',
            CANCELLED_RETAILER: 'ยกเลิกโดยร้านค้าปลีก',
            CANCELLED_STOCKIST: 'ยกเลิกโดยร้านค้าส่ง',
            DELIVERED: 'จัดส่งสำเร็จ'
        },
        ORDER_DETAIL: {
            TITLE: 'ค่าธรรมเนียม',
            DATE_SUCCESS: 'วันที่จัดส่งสำเร็จ',
            RETAILER_NAME: 'ร้านค้าปลีก',
            ORDER: 'คำสั่งซื้อ',
            DETAIL: 'รายละเอียด',
            ORDER_DATE: 'วันที่สั่งซื้อ',
            STOCKIST_NAMES: 'ร้านค้าส่ง',
            EXPECTED_DATE: 'วันที่คาดว่าจะจัดส่ง',
            STATUS: 'สถานะ',
            LAST_UPDATE: 'อัพเดทล่าสุด',
            ORDER_LIST: 'รายการสินค้า',
            PRICES_ORDER_ALL: 'ยอดรวมสินค้าทั้งหมด',
            PIECE: 'ชิ้น',
            DISCOUNT: 'ส่วนลดคูปอง',
            PROMOTION_DISCOUNT: 'ส่วนลดโปรโมชัน',
            SHIPPING: 'ค่าจัดส่ง',
            BILLING: 'ค่าธรรมเนียม 1%',
            TOTAL_COUPON: 'ยอดคูปอง',
            ORDER_FAIL: 'จำนวนสินค้าที่จัดส่งไม่สำเร็จ',
            GET_COUPON_BACK: 'ค่าคูปองที่ได้รับคืน',
            ORIGINAL_ORDER: 'ดูคำสั่งซื้อเดิม',
            NEW_ORDER: 'ดูคำสั่งซื้อใหม่',
            CHANGED_ORDER: 'มีการเปลี่ยนแปลงคำสั่งซื้อ',
            TOTAL_NET_AMOUNT: 'ยอดรวมสุทธิ',
            LIST_PRODUCT_NAME: 'ชื่อสินค้า',
            LIST_PRODUCT_CATEGORY: 'หมวดหมู่',
            LIST_PRODUCT_PRICE_PER_UNIT: 'ราคาต่อหน่วย',
            LIST_PRODUCT_QTY: 'จำนวน',
            LIST_SUM_AMOUNT: 'ราคารวม',
            LIST_PRODUCT_PHOTO: 'รูปสินค้า'
        },
        LIST_PRODUCT_HISTORY: {
            TITLE: 'ประวัติการเพิ่มสินค้า',
            UPLOAD_AT: 'วันที่อัพโหลด',
            UPLOAD_AMOUNT: 'จำนวนที่อัพโหลด',
            SUCCESS_UPLOAD_AMOUNT: 'จำนวนที่อัพโหลดสำเร็จ',
            IMAGE_FILE_NAME: 'ชื่อไฟล์รูปภาพ',
            DATA_FILE_NAME: 'ชื่อไฟล์ข้อมูล',
            CREATE: {
                TITLE_IMAGE: 'ไฟล์รูปภาพ',
                TITLE_DATA: 'ไฟล์ข้อมูล',
                DOWNLOAD_FILE_EXAMPLE: 'ดาวน์โหลดตัวอย่างไฟล์',
                DOWNLOAD_FILE: 'ดาวน์โหลดไฟล์',
                ADD_FILE: 'เพิ่มไฟล์',
                HINT_ADD_IMAGE_FILE: 'กรุณาอัพโหลดไฟล์ zip ของรูปภาพ jpg ขนาดไม่เกิน 100 Mb',
                HINT_ADD_FILE: 'กรุณาอัพโหลดไฟล์ csv ขนาดไม่เกิน 100 Mb',
                ERROR_FILE_TYPE: 'รูปแบบไฟล์ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
                ERROR_FILE_SIZE: 'ขนาดไฟล์เกินที่กำหนด กรุณาลองใหม่อีกครั้ง',
            },
            DETAIL: {
                ERROR_TITLE: 'ข้อผิดพลาด',
                EAN: 'EAN'
            }
        },
        LIST_GIFT: {
            TITLE: 'ของแถม',
            ADD_GIFT: 'เพิ่มของแถม',
            ACTIVATE: 'เปิดการใช้งาน',
            DEACTIVATE: 'ปิดการใช้งาน',
            ALL: 'ทั้งหมด',
            STATUS: 'สถานะ',
            DATA_TABLE: {
                COVER_IMAGE_PATH: 'รูปสินค้า',
                CODE: 'รหัสของแถม',
                NAME: 'ชื่อสินค้า',
                CATEGORY: 'หมวดหมู่'
            },
            CREATE: {
                TITLE: 'เพิ่มของแถม',
                DETAIL_GIFT: 'รายละเอียดของแถม',
                LABEL_NAME: 'ชื่อของแถมภาษาไทย',
                LABEL_NAMEEN: 'ชื่อของแถมภาษาอังกฤษ',
                LABEL_EAN: 'EAN',
                LABEL_LOGO: 'ภาพปก',
                PLACEHOLDER_NAME: 'กรอกชื่อของแถม',
                PLACEHOLDER_NAMEEN: 'กรอกชื่อของแถม',
                PLACEHOLDER_EAN: 'กรอก EAN',
                DESC_GIFT: 'คำอธิบายของแถม',
                LABEL_DESC: 'คำอธิบายภาษาไทย',
                PLACEHOLDER_DESC: 'คำอธิบายภาษาไทย (ไม่บังคับ)',
                LABEL_DESCEN: 'คำอธิบายภาษาอังกฤษ',
                PLACEHOLDER_DESCEN: 'คำอธิบายภาษาอังกฤษ (ไม่บังคับ)',
                CATEGORY_GIFT: 'หมวดหมู่',
                CATEGORY_1: 'ระดับ 1',
                CATEGORY_2: 'ระดับ 2',
                CATEGORY_3: 'ระดับ 3',
                LABEL_SEARCH: 'ค้นหา'
            },
            EDIT: {
                TITLE: 'แก้ไขรายละเอียดของแถม',
            },
            ERROR: {
                NOT_FOUND: "ไม่พบข้อมูล",
                LOADING: "กําลังโหลด..."
            },
            VIEW: {
                TITLE: 'รายละเอียดของแถม',
            }
        },

        GIFT_MODAL: {
            CANCEL: {
                TITLE: 'ข้อมูลยังไม่ถูกบันทึก',
                MESSAGE: 'คุณต้องการที่ออกจากหน้านี้ใช่ไหม?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ยืนยัน',
            },
            DELETE: {
                TITLE: 'ยืนยันการลบของแถม',
                SUBTITLE_1: 'ของแถม',
                SUBTITLE_2: 'EAN',
                MESSAGE: 'คุณต้องการที่จะลบของแถมนี้ใช่หรือไม่ ?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ลบ',
            },
            CLOSE: {
                TITLE: 'ยืนยันการปิดใช้งานของแถม',
                MESSAGE: 'คุณต้องการที่จะปิดการใช้งานของแถมนี้ใช่หรือไม่ ?',
                CONFIRM: 'ปิดการใช้งาน',
                CANCEL: 'ยกเลิก',
            }
        },
        LIST_CMS: {
            TITLE: 'จัดการหน้าหลัก'
        },
        AVAILABLE_STATUS: {
            VALID: 'ใช้ได้',
            INVALID: 'หมดอายุ',
            SCHEDULE: 'ตั้งเวลา'
        },
        LIST_BANNER: {
            TITLE: 'แบนเนอร์',
            IMAGE: 'ภาพแบนเนอร์',
            NAME: 'ชื่อแบนเนอร์',
            ACTIVE_PERIOD: 'ระยะเวลาที่มีผล',
            STATUS: 'สถานะ',
            CREATE: {
                TITLE: 'เพิ่มแบนเนอร์'
            },
            PERMISSION_DENINED: {
                HEADER: "ไม่สามารถทำการเปลี่ยนแปลง",
                DETAIL: "คุณไม่สามารถแก้ไขหรือเปลียนแปลงข้อมูลใดๆได้ เนื่องจากคุณไม่ได้รับสิทธิในการใช้งาน",
                CLOSE: "ปิด"
            }
        },
        LIST_SHOWCASES: {
            TITLE: 'โชว์เคส',
            NAME: 'ชื่อโชว์เคส',
            ACTIVE_PERIOD: 'ระยะเวลาที่มีผล',
            STATUS: 'สถานะ',
            CREATE: {
                TITLE: 'เพิ่มโชว์เคส'
            }
        },
        LIST_BILLING: {
            TITLE: 'ค่าธรรมเนียม',
            TITLE_COLUMN: 'ค่าธรรมเนียม',
            TOTAL_ORDER: 'จำนวนคำสั่งซื้อทั้งหมด',
            TOTAL_AMOUNT: 'ยอดคำสั่งซื้อทั้งหมด',
            TOTAL_ORDER_1: 'จำนวนคำสั่งซื้อ',
            TOTAL_AMOUNT_1: 'ยอดคำสั่งซื้อ',
            TOTAL_BILLING: 'ค่าธรรมเนียมสุทธิ',
            TOTAL_BILLING_1: 'ค่าธรรมเนียม',
            COUPON: 'ค่าคูปอง',
            COUPONS: 'ค่าคูปอง',
            STOCKIST_NAME: 'ชื่อร้านค้า',
            AMOUNT: 'จำนวนคำสั่งซื้อ',
            SERVICE: 'ค่ายริการสุทธิ',
            TOTAL: 'ยอดรวม',
            FEE: 'ค่าธรรมเนียม',
            TOTAL_GMV: 'ยอดรวมสินค้า',
            TOTAL_NMV: 'ยอดสุทธิ',
            DATE_SUCCESS: 'วันที่จัดส่งสำเร็จ',
            RETAILER_NAME: 'ร้านค้าปลีก',
            ORDER: 'คำสั่งซื้อ',
            ORDER_PHOTO: 'รูปสินค้า',
            ORDER_NAME: 'ชื่อสินค้า',
            GIFT_PHOTO: 'รูปของแถม',
            GIFT_NAME: 'ชื่อของแถม',
            CATEGORY: 'หมวดหมู่',
            PRICE_PER_UNIT: 'ราคาต่อหน่วย',
            AMOUNT_DETAIL: 'จำนวน',
            TOTAL_PRICE: 'ราคารวม',
            DETAIL: 'รายละเอียด',
            ORDER_DATE: 'วันที่สั่งซื้อ',
            STOCKIST_NAMES: 'ร้านค้าส่ง',
            EXPECTED_DATE: 'วันที่คาดว่าจะจัดส่ง',
            STATUS: 'สถานะ',
            LAST_UPDATE: 'อัพเดทล่าสุด',
            ORDER_LIST: 'รายการสินค้า',
            GIFT_LIST: 'ของแถม',
            PRICES_ORDER_ALL: 'ยอดรวมสินค้าทั้งหมด',
            PIECE: 'ชิ้น',
            DISCOUNT: 'ส่วนลดคูปอง',
            SHIPPING: 'ค่าจัดส่ง',
            BILLING: 'ค่าธรรมเนียม 1%',
            TOTAL_COUPON: 'ยอดคูปอง',
            PRICE_ORDER_FAIL: 'จำนวนสินค้าที่จัดส่งไม่สำเร็จ',
            GET_COUPON_BACK: 'ค่าคูปองที่ได้รับคืน',
            ORIGINAL_ORDER: 'ดูคำสั่งซื้อเดิม',
            CHANGED_ORDER: 'มีการเปลี่ยนแปลงคำสั่งซื้อ',
            TOTAL_NET: 'ยอดรวมสุทธิ',
            ORDER_ID: 'รหัสคำสั่งซื้อ',
            PROMOTION_DISCOUNT: 'ส่วนลดโปรโมชัน',
            STOCKIST_DETAIL: {
                TITLE: 'ค่าธรรมเนียม',
            }
        },
        PROMOTION: {
            TITLE: 'เพิ่มโปรโมชัน',
            TITLE_EDIT: 'แก้ไขโปรโมชัน',
            TITLE_DETAIL: 'รายละเอียดโปรโมชัน',
            TITLE_TYPE: 'ประเภทโปรโมชัน',
            NAME: 'ชื่อโปรโมชัน',
            DATAIL: 'คำอธิบายโปรโมชัน',
            TIME: 'ช่วงเวลาโปรโมชัน',
            TO: 'ถึง',
            TYPE: 'ประเภทโปรโมชัน',
            TYPE_1: 'ซื้อครบลดเลย',
            TYPE_2: 'ซื้อสินค้ารับของแถม',
            CONDITION: 'เงื่อนไขโปรโมชัน',
            CONDITION_1: 'ขั้นบันได',
            CONDITION_2: 'ชุดสินค้า',
            CONDITION_2_DETAIL: 'เมื่อซื้อสินค้าครบทุกจำนวน',
            GIFT_1: 'จะได้รับของแถม',
            GIFT_2: 'อย่างจากรายการนี้',
            STEP: 'ขั้น',
            BUY_PRODUCT: 'เมื่อซื้อสินค้า',
            AMOUNT_TYPE: 'จนถึงจำนวน',
            AMOUNT_TYPE_1: 'สูงสุดไม่เกิน',
            AMOUNT_TYPE_2: 'ไม่จำกัด',
            DISCOUNT_TYPE: 'จะได้รับส่วนลดชิ้นละ',
            DISCOUNT_TYPE_1: '%',
            DISCOUNT_TYPE_2: '฿',
            DISCOUNT_TYPE_3: 'จะได้รับส่วนลดชิ้นละ',
            ADD_CONDITION: 'เพิ่มเงื่อนไข',
            PRODUCT: 'สินค้าที่เข้าร่วม',
            ADD_PRODUCT: 'เพิ่มสินค้า',
            ADD_GIFT: 'เพิ่มของแถม',
            ALL_PRODUCT_TYPE: 'สินค้าทั้งหมดที่ขาย',
            ALL_PRODUCT_TYPE_1: 'ไม่จำกัด',
            ALL_PRODUCT_TYPE_2: 'จำกัด',
            ALL_STOCKIST_TYPE: 'จำนวนร้านค้าที่ซื้อได้',
            ALL_STOCKIST_TYPE_1: 'ไม่จำกัด',
            ALL_STOCKIST_TYPE_2: 'จำกัด',
            FULL_UNIT: 'ครบทุกจำนวน',
            UNIT: 'จำนวน',
            PRODUCT_TITLE: 'สินค้า'
        },

        PROMOTION_HINT: {
            NAME: 'ชื่อโปรโมชัน',
            DATAIL: 'คำอธิบายโปรโมชัน',
            TIME: 'ช่วงเวลาโปรโมชัน',
            HINT_TIME: '00',
            TYPE: 'ประเภทโปรโมชัน',
            TYPE_1: 'ซื้อครบลดเลย',
            TYPE_2: 'ซื้อสินค้ารับของแถม',
            CONDITION: 'เงื่อนไขโปรโมชัน',
            CONDITION_1: 'ขั้นบันได',
            CONDITION_2: 'ชุดสินค้า',
            STEP: 'ขั้น',
            BUY_PRODUCT: 'เมื่อซื้อสินค้า',
            AMOUNT_TYPE: 'จนถึงจำนวน',
            AMOUNT_TYPE_1: 'สูงสุดไม่เกิน',
            AMOUNT_TYPE_2: 'ไม่จำกัด',
            DISCOUNT_TYPE: 'จะได้รับส่วนลด',
            DISCOUNT_TYPE_1: '%',
            DISCOUNT_TYPE_2: '฿',
            ADD_CONDITION: 'เพิ่มเงื่อนไข',
            PRODUCT: 'สินค้าที่เข้าร่วม',
            ADD_PRODUCT: 'เพิ่มสินค้า',
            ALL_PRODUCT_TYPE: 'สินค้าทั้งหมดที่ขาย',
            ALL_PRODUCT_TYPE_1: 'ไม่จำกัด',
            ALL_PRODUCT_TYPE_2: 'จำกัด',
            ALL_STOCKIST_TYPE: 'จำนวนร้านค้าที่ซื้อได้',
            ALL_STOCKIST_TYPE_1: 'ไม่จำกัด',
            ALL_STOCKIST_TYPE_2: 'จำกัด',
            EVERY: 'กรอกจำนวน',
            QUANITY: 'กรอกจำนวน'
        },

        PROMOTION_TAB: {
            STOCKIST_PRODUCT: 'สินค้า',
            GIFT: 'ของแถม'
        },

        LIST_PROMOTION: {
            PROMOTION: 'โปรโมชัน',
            ADD_PROMOTION: 'เพิ่มโปรโมชัน',
            NAME: 'ชื่อโปรโมชัน',
            PROMOTION_TYPE: 'ประเภทโปรโมชัน',
            CONDITION_PROMOTION: 'เงื่อนไขส่วนลด',
            PERIOD_PROMOTION: 'ระยะเวลาโปรโมชัน',
            STATUS: 'สถานะ',
            PROMOTION_GIFT: 'ซื้อสินค้ารับของแถม',
            PROMOTION_DISCOUNT: 'ซื้อครบลดเลย',
            CONDITION_TIERED: 'ขั้นบันได',
            CONDITION_BUNDLE: 'ชุดสินค้า',
            STATUS_ACTIVE: 'ใช้ได้',
            STATUS_EXPIRED: 'หมดอายุ',
            STATUS_WAITING: 'ยังไม่เริ่มใช้งาน',
            STATUS_INACTIVE: 'ปิดการใช้งาน',
            TITLE_ERR_ACTIVE: 'ไม่สามารถเปิดใช้งานโปรโมชันได้',
            MESSAGE_ERR_ACTIVE: 'เนื่องจากยังมีโปรโมชันชื่อเดียวกันใช้งานอยู่',
            BTN_CANCEL: 'ยกเลิก',
            MODAL_TITTLE: 'ยืนยันปิดการใช้งานโปรโมชัน',
            MODAL_MESSAGE: 'คุณต้องการที่จะปิดการใช้งานโปรโมชันนี้ใช่หรือไม่ ?',
            MODAL_CONFIRM_LABEL: 'ปิดการใช้งาน',
            MODAL_CANCEL_LABEL: 'ยกเลิก'
        },
        PROMOTION_STATUS: {
            ALL: 'ทั้งหมด',
            ACTIVE: 'เปิดการใช้งาน',
            INACTIVE: 'ปิดการใช้งาน',
        },

        PROMOTION_MODAL: {
            CANT_DELETE: {
                TITLE: 'ไม่สามารถลบโปรโมชันได้',
                MESSAGE:
                    '',
                CANCEL: 'ยกเลิก',
            },
            CANT_CREATE: {
                TITLE: 'ไม่สามารถสร้างโปรโมชันได้',
                SUBTITLE_1: 'เนื่องจากมีโปรโมชันเงื่อนไขเดียวกันถูกสร้างไว้แล้ว',
                MESSAGE:
                    'คุณต้องการที่ออกจากหน้านี้ใช่ไหม?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ออก',
            },

            CANT_EDIT: {
                TITLE: 'ไม่สามารถแก้ไขโปรโมชันได้',
                SUBTITLE_1: 'เนื่องจากมีโปรโมชันเงื่อนไขเดียวกันถูกสร้างไว้แล้ว',
                MESSAGE:
                    'คุณต้องการที่ออกจากหน้านี้ใช่ไหม?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ออก',
            },

            CANCEL: {
                TITLE: 'ข้อมูลยังไม่ถูกบันทึก',
                MESSAGE: 'คุณต้องการที่ออกจากหน้านี้ใช่ไหม?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ยืนยัน',
            },
            DELETE: {
                TITLE: 'ยืนยันการลบโปรโมชัน',
                SUBTITLE_1: 'โปรโมชัน',
                SUBTITLE_2: 'รหัสโปรโมชัน',
                MESSAGE: 'คุณต้องการที่จะลบโปรโมชันนี้ใช่หรือไม่ ?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ยืนยัน',
            },
        },

        NOTIFICATION: {
            TITTLE_CREATE: 'สร้างการแจ้งเตือน',
            TITTLE_EDIT: 'แก้ไขการแจ้งเตือน',
            TITTLE_VIEW: 'ดูรายละเอียดารแจ้งเตือน',
            TITTLE_HISTORY: 'ประวัติการแจ้งเตือน',
            SETTING_NOTIFICATION: 'ตั้งค่าการแจ้งเตือน',
            DETAIL: 'รายละเอียด',
            SUBJECT: 'หัวข้อ (ภาษาไทย)',
            SUBJECT_EN: 'หัวข้อ (ภาษาอังกฤษ)',
            HINT_SUBJECT: 'หัวข้อการแจ้งเตือน',
            SHORT_MSG: 'ข้อความแบบย่อ (ภาษาไทย)',
            SHORT_MSG_EN: 'ข้อความแบบย่อ (ภาษาอังกฤษ)',
            HINT_SHORT_MSG: 'ข้อความแบบย่อ (ภาษาไทย)',
            HINT_SHORT_MSG_EN: 'ข้อความแบบย่อ (ภาษาอังกฤษ)',
            FULL_MSG: 'ข้อความแบบเต็ม (ภาษาไทย)',
            FULL_MSG_EN: 'ข้อความแบบเต็ม (ภาษาอังกฤษ)',
            HINT_FULL_MSG_EN: 'ข้อความแบบเต็ม (ภาษาอังกฤษ)',
            HINT_FULL_MSG: 'ข้อความแบบเต็ม (ภาษาไทย)',
            RADIO_IMMEDIATELY: 'ทันที',
            RADIO_SCHEDULE: 'ตั้งเวลา',
            TYPE: 'ประเภทการแจ้งเตือน',
            HINT_NOTIFICATION: 'ประเภทการแจ้งเตือน',
            ATTACHMENT_PHOTO: 'แนบรูปประกอบ',
            SAVE: 'บันทึก',
            CANCEL: 'ยกเลิก',
            CANCEL_NOTIFICATION: 'ยกเลิกการแจ้งเตือน',
            NEWS: 'ข่าวสาร',
            COUPON: 'คูปอง',
            PROMOTION: 'โปรโมชัน',
            RECEIVER_TYPE_TITTLE: 'กำหนดสิทธิ์ผู้รับ',
            RECEIVER_TYPE_ALL: 'ทั้งหมด',
            RECEIVER_TYPE_EXPECT: 'ยกเว้นร้านค้า',
            RECEIVER_TYPE_SPECIFIC: 'เฉพาะร้านค้่า',
            CREATE: {
                TITLE_IMAGE: 'ไฟล์รูปภาพ',
                TITLE_DATA: 'ไฟล์ข้อมูล',
                DOWNLOAD_FILE_EXAMPLE: 'ดาวน์โหลดตัวอย่างไฟล์',
                DOWNLOAD_FILE: 'ดาวน์โหลดไฟล์',
                ADD_FILE: 'เพิ่มไฟล์',
                HINT_ADD_IMAGE_FILE: 'กรุณาอัพโหลดไฟล์ jpg หรือ png ขนาดไม่เกิน 100 Mb',
                HINT_ADD_FILE: 'กรุณาอัพโหลดไฟล์ csv ขนาดไม่เกิน 100 Mb',
                ERROR_FILE_TYPE: 'รูปแบบไฟล์ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
                ERROR_FILE_SIZE: 'ขนาดไฟล์เกินที่กำหนด กรุณาลองใหม่อีกครั้ง',
            },
            EDIT: {
                WARNING_SUCCESS: 'ส่งการแจ้งเตือนสำเร็จแล้ว',
                WARNING_CANCEL: 'ยกเลิกการแจ้งเตือนแล้ว',
            }
        },
        LIST_NOTIFICATION: {
            TITLE: 'การแจ้งเตือน',
            ADD_NOTIFICATION: 'เพิ่มการแจ้งเตือน',
            STATUS: {
                PENDING: 'ตั้งล่วงหน้า',
                SUCCESS: 'สำเร็จ',
                CANCEL: 'ยกเลิก',
                ALL: 'ทั้งหมด'
            },
            DATA_TABLE: {
                COL1: 'หัวข้อการแจ้งเตือน',
                COL2: 'ข้อความแบบย่อ',
                COL3: 'ประเภท',
                COL4: 'วันที่และเวลาแจ้งเตือน',
                COL5: 'สถานะ'
            },
            TYPE: {
                NEWS: 'ข่าวสาร',
                PROMOTION: 'โปรโมชัน',
                COUPON: 'คูปอง',
                ORDER: 'ออเดอร์'
            }
        },
        NOTIFICATION_MODAL: {
            CANCEL: {
                TITLE: 'ข้อมูลยังไม่ถูกบันทึก',
                MESSAGE: 'คุณต้องการที่ออกจากหน้านี้ใช่ไหม?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ยืนยัน',
            },
            CANCEL_NOTIFICATION: {
                TITLE: 'ยืนยันการยกเลิก',
                MESSAGE: 'การแจ้งเตือนนี้จะถูกยกเลิก\nคุณต้องการจะดำเนินการต่อใช่หรือไม่?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ยืนยัน',
            },
        },
        CMS: {
            LIST_SHOWCASE: {
                MODAL_TITTLE: "ยืนยันการปิดการใช้งานโชว์เคส",
                MODAL_MESSAGE: "คุณต้องการที่จะปิดการใช้งานโชว์เคสนี้ใช่หรือไม่ ?",
                MODAL_CONFIRM: "ปิดการใช้งาน",
                MODAL_CANCEL: "ยกเลิก",
            },
            LIST_BANNER: {
                MODAL_TITTLE: "ยืนยันการปิดการใช้งานแบนเนอร์",
                MODAL_MESSAGE: "คุณต้องการที่จะปิดการใช้งานแบนเนอร์นี้ใช่หรือไม่ ?",
                MODAL_CONFIRM: "ปิดการใช้งาน",
                MODAL_CANCEL: "ยกเลิก",
            },
            CREATE: {
                TITLE: "เพิ่มโชว์เคส",
                TITLE_EDIT: 'แก้ไขโชว์เคส',
                SUBTITLE_NAME: "ชื่อโชว์เคส",
                LABEL_NAME: "ชื่อภาษาไทย* (TH)",
                LABEL_NAMEEN: "ชื่อภาษาอังกฤษ* (ENG)",
                PLACEHOLDER_NAME: "กรอกชื่อหัวข้อ",
                PLACEHOLDER_DISPLAY: "เลือกการเรียงลำดับ",
                LABEL_DISPLAY: 'เรียงตาม',
                LABEL_TIME: "ช่วงเวลา",
                DISPLAY: "การแสดงผล",
                PROMOTION: "โปรโมชัน",
                PRODUCT: "สินค้า",
                BRAND: "แบรนด์",
                CATEGORIES: "หมวดหมู่",
                SORT_OPTION: {
                    PRICE_LOW_TO_HIGH: "ราคาขาย : น้อย - มาก",
                    PRICE_HIGH_TO_LOW: "ราคาขาย : มาก - น้อย",
                    LAST_UPDATE: "สินค้าใหม่",
                    AMOUNT_TOTAL_PURCHASES: "จำนวนการซื้อสูงสุด 6 เดือนที่ผ่านมา",
                    AMOUNT_TOTAL_SALES: "ยอดขายสูงสุด 6 เดือนที่ผ่านมา",
                    AMOUNT_TOTAL_SALES_DAY: "ยอดขายสูงสุด 15 วันที่ผ่านมา",
                },
                ERROR_DISPLAY: 'เลือกตัวเลือกสินค้าอย่างน้อย 1 อย่าง'
            }
        },
        FORM_BANNER: {
            TITLE_CREATE: 'เพิ่มแบนเนอร์',
            TITLE_EDIT: 'แก้ไขแบนเนอร์',
            TITLE_NAME: 'ชื่อแบนเนอร์',
            NAME_TH: 'ชื่อภาษาไทย*',
            NAME_TH_PLACEHOLDER: 'ชื่อภาษาไทย',
            NAME_EN: 'ชื่อภาษาอังกฤษ*',
            NAME_EN_PLACEHOLDER: 'ชื่อภาษาอังกฤษ',
            TITLE_TIME: 'ช่วงเวลา',
            TIME_TO: 'ถึง',
            TITLE_IMAGE: 'แบนเนอร์',
            TITLE_PERMISSION_RETAILER: 'กำหนดสิทธิ์การมองเห็นของร้านค้าปลีก',
            PERMISSION_RETAILER: {
                ALL: 'ทุกร้านค้า',
                EXCEPT: 'ยกเว้นร้านค้า',
                SPECIFIC: 'เฉพาะร้านค้า'
            },
            FILE: {
                ADD_FILE: 'เพิ่มไฟล์',
                PLACEHOLDER_FILE: 'กรุณาอัพโหลดไฟล์ csv ขนาดไฟล์ไม่เกิน 100 Mb',
                PLACEHOLDER_IMAGE: 'กรุณาอัพโหลดไฟล์ jpg หรือ png ขนาดไม่เกิน 100Mb',
                DOWNLOAD_EXAMPLE: 'ดาวน์โหลดตัวอย่างไฟล์',
                DOWNLOAD_FILE: 'ดาวน์โหลดไฟล์',
                ERROR: {
                    ERROR_FILE_TYPE: 'รูปแบบไฟล์ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
                    ERROR_FILE_SIZE: 'ขนาดไฟล์เกินที่กำหนด กรุณาลองใหม่อีกครั้ง',
                    ERROR_FILE_REQUIRED: 'กรุณาใส่ไฟล์'
                }
            },
            TITLE_SHOWCASE: 'แบนเนอร์แอคชั่น',
            SHOWCASE_CONDITION: {
                NO: 'ไม่มี',
                SHOWCASE: 'โชว์เคส',
                PROMOTION: 'กำหนดสินค้า'
            },
            SHOWCASE: {
                TITLE_SELECT_SHOWCASE: 'โชว์เคส',
                PLACEHOLDER: 'โชว์เคส',
                ERROR: {
                    NOT_SELECT: 'กรุณาเลือกโชว์เคส'
                }
            },
            PROMOTION: {
                DISCOUNT: 'ส่วนลด',
                GIFT: 'ของแถม',
                TITLE_PRODUCT: 'สินค้า',
                STOCKIST: 'ร้านค้าส่ง',
                STOCKIST_PLACEHOLDER: 'เลือกร้านค้าส่ง',
                BRAND: 'แบรนด์',
                BRAND_PLACEHOLDER: 'เลือกแบรนด์',
                SELECTED_PLACEHOLDER: 'เลือกอยู่ {{count}} รายการ',
                CATEGORY: 'หมวดหมู่',
                CATEGORY_PLACEHOLDER: 'เลือกหมวดหมู่',
                PROMOTION: 'โปรโมชัน',
                ERROR: {
                    NOT_SELECT: 'เลือกโปรโมชันหรือสินค้าอย่างน้อย 1 อย่าง'
                }
            },
            DELETE: {
                TITLE: 'ยืนยันการลบแบนเนอร์',
                MESSAGE: 'คุณต้องการที่จะลบแบนเนอร์นี้ใช่หรือไม่ ?',
                CANCEL: 'ยกเลิก',
                CONFIRM: 'ลบ',
            },
            ERROR: {
                TITLE: 'เกิดข้อผิดพลาด',
                MESSAGE: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                CANCEL: 'ปิด',
            }
        },
        UPLOAD_PRODUCT_HISTORY_DETAIL: {
            TITLE: 'ผลการอัพโหลด',
            STATUS: {
                TITLE: 'สถานะ',
                UPLOAD_AT: 'วันที่อัพโหลด',
                UPLOAD_BY: 'อัพโหลดโดย',
                UPLOAD_AMOUNT: 'จำนวนที่อัพโหลด',
                SUCCESS_UPLOAD_AMOUNT: 'จำนวนที่อัพโหลดสำเร็จ',
                IMAGE_FILE_NAME: 'ไฟล์ภาพ',
                IMAGE_FILE_DATA: 'ไฟล์ข้อมูล'
            },
            LIST_UNSUCCESS_UPLOAD: {
                TITLE: 'สินค้าที่อัพโหลดไม่สำเร็จ',
                ERROR_TYPE: {
                    BRAND: 'แบรนด์ไม่ถูกต้อง',
                    CATEGORY: 'หมวดหมู่ไม่ถูกต้อง',
                    EAN: 'EAN ซ้ำกับในระบบ',
                    IMAGE: 'รูปภาพไม่ถูกต้อง',
                    LENGTH: 'กรุณาตรวจสอบ EAN ต้องมีความยาวขั้นต่ำ\n13 ตัวและสูงสุดไม่เกิน 20 ตัว',
                    ERROR_TITLE: 'ไม่สามารถอัพโหลดไฟล์',
                }
            }
        },
        PERMISSION_MODAL: {
            PERMISSION: {
                TITLE: 'ไม่สามารถทำการเปลี่ยนแปลง',
                MESSAGE: 'คุณไม่สามารถแก้ไขหรือเปลี่ยนแปลงข้อมูลใดๆ ได้ เนื่องจากคุณไม่ได้รับสิทธิในการใช้งาน',
                CONFIRM: 'ปิด',
            }
        },
        NOT_FOUND: {
            MESSAGE: 'ไม่พบข้อมูล'
        },
        REPORT: {
            TITLE: 'รายงาน',
            SUBTITLE: 'รายละเอียด',
            CUSTOMER_REPORT: 'รายชื่อร้านค้าปลีก',
            DOWNLOAD: 'ดาวน์โหลด',
            ORDER_REPORT: 'รายการคำสั่งซื้อ',
            ORDER_ITEM: 'สินค้าในคำสั่งซื้อ',
            ACTIVE_PRODUCT: 'สินค้าที่เปิดใช้งาน',
        }
    },
}
