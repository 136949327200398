/* eslint-disable @typescript-eslint/no-explicit-any */
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Injectable,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ICheckManagedShelf } from '@core/models/stockist/stockist.model';
import { TranslateService } from '@ngx-translate/core';
import { Subject, merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
    DatatabelColumn,
    DatatableParamsChange,
} from './interfaces/datatable.interface';

@Injectable()
export class CustomPaginatorIntl implements MatPaginatorIntl {
    changes = new Subject<void>();
    constructor(private translate: TranslateService) { }

    firstPageLabel = `First page`;
    itemsPerPageLabel = this.translate.instant('PAGINATOR.ITEM_PER_PAGE');
    lastPageLabel = `Last page`;
    nextPageLabel = 'Next page';
    previousPageLabel = 'Previous page';

    getRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0) {
            return `Page 1 of 1`;
        }
        const totalItems = length;
        const startIndex = page * pageSize + 1;
        const endIndex = Math.min((page + 1) * pageSize, length);
        return `${startIndex}-${endIndex} ${this.translate.instant('PAGINATOR.FROM')} ${totalItems} ${this.translate.instant('PAGINATOR.ITEM')}`;
    }
}

@Component({
    selector: 'app-datatable',
    templateUrl: './datatable.component.html',
    styleUrls: ['./datatable.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }],
})
export class DatatableComponent implements OnInit, OnChanges {
    @Input() pageSize = 10;
    @Input() pageTotal = 0;
    @Input() pageSizeOptions: Array<number> = [];
    @Input() dataColums: DatatabelColumn[] = [];
    @Input() pageLoading = false;
    @Input() paginationOption = false;
    @Input() disableDelete = true;
    @Input() disableShelf = true;
    @Input() disableToggle = true;
    @Input() disableViewDetail = true
    @Input() disabledEdit = false;
    @Input() statusPropertyPath = 'status';
    @Input() isShelfManagement = 'false';
    @Input() isShelfManagedList: ICheckManagedShelf[] = [];
    @Input() disableColumnAction = false;

    rowData: any;

    @Input()
    set pageIndex(value: number) {
        this.paginator.pageIndex = value - 1;
    }
    amount = 0;
    @Input()
    set dataSource(value: any[]) {
        this.sourses = value.filter((item: any) => item.quantity !== 0).map((data: any, i) => {
            return {
                ...data,
                _id: this.paginator.pageIndex * this.pageSize + i + 1,
            };
        });
    }

    @Input() isEnabledColumnImage = false;
    @Input() isEnabledDragAndDrop = false;
    @Input() isEnabledDuplicate = false;
    @Input() titleColumnImage = '';

    @Output() valueChange = new EventEmitter<DatatableParamsChange>();
    @Output() clicked = new EventEmitter<any>();
    @Output() mouseenterChange = new EventEmitter<any>();
    @Output() edited = new EventEmitter<any>();
    @Output() deleted = new EventEmitter<any>();
    @Output() duplicate = new EventEmitter<any>();
    @Output() shelf = new EventEmitter<any>();
    @Output() toggleStatus = new EventEmitter<any>();
    @Output() viewDetail = new EventEmitter<any>();
    @Output() dropValueChange = new EventEmitter<any>();

    toggle(item: any): void {
        this.toggleStatus.emit(item);
    }

    displayedColumns: Array<string> = [];
    order = '';
    sourses: any[] = [];

    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort!: MatSort;
    @ViewChild('table', { static: true }) table: MatTable<any> | undefined;

    constructor(private translate: TranslateService) { }

    ngOnInit() {
        const initialColumns = [];

        if (this.isEnabledDragAndDrop) {
            initialColumns.push({
                id: '_drag',
                title: '',
                sortDisabled: true,
            });
        }

        if (this.isEnabledColumnImage) {
            initialColumns.push({
                id: '_image',
                title: this.translate.instant(this.titleColumnImage),
                sortDisabled: true,
            });
        }

        this.dataColums = [
            ...initialColumns,
            ...this.dataColums,
        ];

        if (!this.disableColumnAction) {
            this.dataColums.push({
                id: '_action',
                title: '',
                sortDisabled: true,
            });
        }

        this.displayedColumns = this.dataColums.map((item) => item.id);

        this.sort.sortChange.subscribe((sort) => {
            this.order = sort.active + ' ' + sort.direction;
            this.paginator.pageIndex = 0;
        });

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => {
                    this.loadItems();
                }),
            )
            .subscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['dataColums'] && !changes['dataColums'].firstChange) {
            this.dataColums = [
                ...this.dataColums,
                {
                    id: '_action',
                    title: '',
                    sortDisabled: true,
                },
            ];
            this.displayedColumns = this.dataColums.map((item) => item.id);
        }
    }

    loadItems() {
        const result: any = {
            limit: this.paginator.pageSize,
            order: this.order,
            page: this.paginator.pageIndex + 1,
        };
        Object.keys(result).forEach(
            (key) => !result[key] && delete result[key],
        );
        this.valueChange.emit(result);
    }

    onClick(item: any) {
        this.clicked.emit(item);
    }

    onEdit(item: any) {
        this.edited.emit(item);
    }

    onDuplicate(item: any) {
        this.duplicate.emit(item);
    }

    onDelete(item: any) {
        this.deleted.emit(item);
    }

    onShelf(item: any) {
        this.shelf.emit(item);
    }

    onViewDetail(item: any) {
        this.viewDetail.emit(item)
    }

    setDisabledState(isDisabled: boolean) {
        return isDisabled;
    }

    handleImageError(event: any) {
        event.target.src = 'assets/media/image_error.jpg';
    }

    onCheckManagedShelf(id: number) {
        if (this.isShelfManagedList.length > 0) {
            if (this.isShelfManagedList.find((x) => x.id === id)) {
                return true
            }
            return false
        }
        return false
    }

    dragDisabled = true;
    drop(event: CdkDragDrop<string>) {
        this.dragDisabled = true;
        const previousIndex = this.sourses.findIndex(d => d === event.item.data);
        moveItemInArray(this.sourses, previousIndex, event.currentIndex);
        if (this.table) {
            this.table.renderRows();
        }
        this.dropValueChange.emit(event)
    }
}