// tab-button.component.ts
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tab-button',
  templateUrl: './tab-button.component.html',
  styleUrls: ['./tab-button.component.scss']
})
export class TabButtonComponent {
  @Input() label = '';
  @Input() active = false;
  @Input() mode = ''
  @Output() tabSelected: EventEmitter<void> = new EventEmitter<void>();


  onClick(): void {
    this.tabSelected.emit();
  }
}
