<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="inputContent"></ng-container>
</ng-container>

<ng-template #default>
  <div class="form-group validated">
    <label class="form-control-label" [for]="name">{{ label  }}</label>
    <ng-container [ngTemplateOutlet]="inputContent"></ng-container>
  </div>
</ng-template>

<ng-template #inline>
  <div class="form-group row validated">
    <label class="col-md-3 col-sm-12 input-inline-label form-control-label m-t-6" [for]="name">{{
      label
    }}</label>
    <div class="col-md-9 col-sm-12 m-t-6">
      <ng-container [ngTemplateOutlet]="inputContent"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #inputContent>
  <mat-radio-group [formControl]="form">
    <mat-radio-button [value]="option.id" *ngFor="let option of options$ | async" (change)="onSelect($event)">{{
      option.name
    }}</mat-radio-button>
  </mat-radio-group>
  <div class="invalid-feedback">{{ errorMessage  }}</div>
</ng-template>
