/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  forwardRef,
  ViewEncapsulation,
  Output,
  EventEmitter,
  TemplateRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subscription, Observable, Subject, concat, of } from 'rxjs';
import {
  distinctUntilChanged,
  debounceTime,
  tap,
  switchMap,
  catchError,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { LabelMode } from '../_enums/label-mode.enum';
import { ConfigLabelMode } from '../_config/label.config';
import { ErrorMessage } from '@modules/error-message';

@Component({
  selector: 'app-select-search-th',
  templateUrl: './select-search-th.component.html',
  styleUrls: ['./select-search-th.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectSearchThComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SelectSearchThComponent
  implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() bindLabel = 'name_th';
  @Input() bindValue = 'id';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() submitted = false;
  @Input() required = false;
  @Input() clearable = true;
  @Input() searchable = true;
  @Input() hideSelected = true;
  @Input() limit = '';
  @Input() isCustomSubDistrict = false;
  @Input() customWidth = false;
  @Input() customTemplate: TemplateRef<any> | undefined;
  @Input() name = '';

  @Input()
  set labelMode(value: LabelMode) {
    this.mode = value;
  }

  LabelMode = LabelMode;
  mode: LabelMode = new ConfigLabelMode().default();

  @Input()
  set params(value: any) {
    this.apiParams.keywords = value;

    this.setTypeahead(this.form.value);
  }

  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input()
  set options(value: Array<any>) {
    this.options$ = of(value);
  }

  @Input()
  set url(value: string) {
    this.isTypeahead = true;
    this.apiUrl = value;
  }

  @Input()
  set value(value: number) {
    this.setTypeahead(value);
    this.form.setValue(value);
  }

  @Input()
  set error(value: any) {
    this.isError = false;
    this.errorMessage = '';

    if (value) {
      this.isError = true;
      this.errorMessage = new ErrorMessage().getErrorMessage(value);
    }
  }

  @Output() valueChange = new EventEmitter();
  @Output() subDistrictChange = new EventEmitter();
  @Output() valueClear = new EventEmitter();

  form: FormControl = new FormControl('');
  subscription: Subscription = new Subscription;

  isRequired = false;
  isLoading = false;
  isTypeahead = false;
  isError = false;
  options$: Observable<any> = of([]);
  search$: Subject<string> = new Subject<string>();
  errorMessage = '';
  apiUrl = '';
  apiParams: any = {};

  onChange = (value: string) => {
    return value;
  };
  onTouched = (value: string) => {
    return value;
  };

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.subscription = this.form.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.onChange(this.form.value);
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  writeValue(value: any): void {
    delete this.apiParams.id;
    this.setTypeahead(value);
    this.form.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    return isDisabled;
  }

  onSelect(value: any) {
    this.valueChange.emit(value);
  }

  setTypeahead(defaultId: number) {
    if (!this.isTypeahead) {
      return;
    }

    this.options$ = concat(
      this.setDefault(defaultId), // default items
      this.search$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.isLoading = true)),
        switchMap((keyword: string) =>
          this.search(keyword).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.isLoading = false))
          )
        )
      )
    );
  }

  search(keyword: string): Observable<any> {
    let filters = {
      keywords: keyword ? keyword : '',
      limit: this.limit,
    };
    if (this.apiParams) {
      filters = Object.assign({}, filters, this.apiParams);
    }
    return this.getHttp(filters);
  }

  getHttp(params?: any): Observable<any> {
    return this.http
      .get('/' + this.apiUrl, { params })
      .pipe(catchError(() => of([])));
  }

  async setDefault(id: number) {
    let results = [];
    results = await this.search('').toPromise();

    if (id) {
      const isAlready: boolean = results.map((obj: any) => obj.id).indexOf(id) > -1;
      if (!isAlready) {
        this.apiParams.id = id;
        const defaultResult = await this.search('').toPromise();

        if (defaultResult.data) {
          const value = defaultResult.data[0];
          results.push(value);
        } else {
          const value = defaultResult[0];
          results.push(value);
        }


      }
    }

    return results;
  }

  onClear() {
    this.valueClear.emit();
  }
}
