<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="inputContent"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.OUTSIDE" [ngTemplateOutlet]="outside"></ng-container>
</ng-container>

<ng-template #default>
  <div class="form-group validated">
    <label class="form-control-label" [for]="name">{{ label | translate  }}</label>
    <ng-container [ngTemplateOutlet]="inputContent"></ng-container>
  </div>
</ng-template>

<ng-template #inline>
  <div class="form-group row validated">
    <label class="col-md-3 col-sm-12 input-inline-label form-control-label" [for]="name">{{ label | translate  }}</label>
    <div class="col-md-9 col-sm-12">
      <ng-container [ngTemplateOutlet]="inputContent"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #outside>
  <div class="form-group validated">
    <label class="form-control-label" [for]="name">{{ label | translate  }}</label>
    <ng-container [ngTemplateOutlet]="inputContentOutside"></ng-container>
  </div>
</ng-template>

<ng-template #inputContent>
  <ng-select
    [ngClass]="{ 'is-invalid': isError }"
    [items]="options$ | async"
    bindLabel="{{ bindLabel }}"
    bindValue="{{ bindValue }}"
    [formControl]="form"
    [hideSelected]="hideSelected"
    [placeholder]="placeholder | translate "
    [loading]="isLoading"
    [typeahead]="search$"
    (change)="onSelect($event)"
    (clear)="onClear()"
    [searchable]="searchable"
    [clearable]="clearable"
    [multiple]= "true"
    [dropdownPosition]="positionDropdown"
  >
  </ng-select>
  <div class="invalid-feedback">{{ errorMessage | translate  }}</div>
</ng-template>

<ng-template #inputContentOutside>
  <ng-select
    [ngClass]="{ 'is-invalid': isError }"
    [closeOnSelect]="false"
    [items]="options$ | async"
    bindLabel="{{ bindLabel }}"
    bindValue="{{ bindValue }}"
    [formControl]="form"
    [placeholder]="placeholder | translate "
    [loading]="isLoading"
    [typeahead]="search$"
    (change)="onSelect($event)"
    (clear)="onClear()"
    [searchable]="searchable"
    [clearable]="clearable"
    [multiple]= "true"
    [dropdownPosition]="positionDropdown"
  >
  <ng-template ng-multi-label-tmp>
    <div>{{ 'FORM_BANNER.PROMOTION.SELECTED_PLACEHOLDER' | translate : {count:isSelectId.length} }}</div>
  </ng-template>
  </ng-select>

  <span class="text-options px-1 mr-1 fs-xs" *ngFor="let item of isSelectId; let i = index">
    {{currentLang === 'th' ? item.name : (item.nameEN ?? item.nameEn)}}
  </span>
  <div class="invalid-feedback">{{ errorMessage | translate  }}</div>
</ng-template>
