/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { ModalMessageComponent } from '../modal-message/modal-message.component';
import { ButtonTag } from '@core/models/shares/enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-input-logo-image',
    templateUrl: './input-logo-image.component.html',
    styleUrls: ['./input-logo-image.component.scss'],
})
export class InputLogoImageComponent {
    modalRef?: NgbModalRef;
    image_Logo_Url$ = new BehaviorSubject<ArrayBuffer | null>(null);
    @Input() image_Logo_Url: string[] = [];
    @Input() mutiple = false
    @Input() isDisableDelete = false
    image_Logo = [];
    @Input() imageName = '';
    @Output() imageChange: EventEmitter<File | undefined> = new EventEmitter<
        File | undefined
    >();
    @Output() deleteImage: EventEmitter<undefined> =
        new EventEmitter<undefined>();

    constructor(
        private modalService: NgbModal,
        private translate: TranslateService
    ) { }

    async removeImage() {
        const result = await this.openDeleteModal();
        if (!result) {
            return;
        }

        this.image_Logo_Url = [];
        this.image_Logo_Url$ = new BehaviorSubject<ArrayBuffer | null>(null);
        this.imageChange.emit(undefined);
        this.deleteImage.emit();
    }

    error_size = this.translate.instant('VALIDATOR.SIZE_FILE');
    error_type = this.translate.instant('VALIDATOR.TYPE_FILE');
    file_error = this.translate.instant('VALIDATOR.UPLOAD_FILE');

    haveErrorSize = false;
    haveErrorType = false;
    haveErrorFile = false;

    processFiles(imageInput: any) {
        this.image_Logo_Url = [];
        this.image_Logo_Url$ = new BehaviorSubject<ArrayBuffer | null>(null);
        const files: FileList = imageInput.files;
        const selected = files;
        if (selected.length > 0) {
            const acceptedFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
            const maxFileSize = 5 * 1024 * 1024;

            if (selected[0].size > maxFileSize) {
                this.haveErrorSize = true;
            } else {
                this.haveErrorSize = false;
            }

            if (!acceptedFileTypes.includes(selected[0].type)) {
                this.haveErrorType = true;
            } else {
                this.haveErrorType = false;
            }
            if (!this.haveErrorSize || !this.haveErrorType) {
                const reader = new FileReader();
                reader.onload = () => {
                    if (reader.result) {
                        const arrayBuffer = reader.result as ArrayBuffer;
                        this.image_Logo_Url$.next(arrayBuffer);
                        const valueToAdd = this.image_Logo_Url$.value || '';
                        this.image_Logo_Url.push(valueToAdd.toString());
                    }
                };
                reader.readAsDataURL(selected[0]);
                this.imageChange.emit(selected[0]);
            }
        }
    }

    onClick() {
        const elementId_old = 'image-input';
        const element_old = document.getElementById(elementId_old.toString());
        this.clearFileInput(element_old);
        const elementId = 'image-input';
        const element = document.getElementById(elementId.toString());
        if (element) {
            element.click();
        } else {
            console.error(`Element with ID ${elementId} not found.`);
        }
    }

    async openDeleteModal() {
        this.modalRef = this.modalService.open(ModalMessageComponent, {
            centered: true,
        });
        this.modalRef.componentInstance.modalConfig = {
            colorButton: ButtonTag.Info,
            title: this.translate.instant('MODAL_IMAGE.TITLE'),
            message: this.translate.instant('MODAL_IMAGE.MESSAGE'),
            confirmLabel: this.translate.instant('MODAL_IMAGE.CONFIRM'),
            cancelLabel: this.translate.instant('MODAL_IMAGE.CANCEL'),
        };
        const confirm: boolean = await this.modalRef.result;
        return confirm;
    }

    clearFileInput(imageInput: any) {
        imageInput.value = '';
    }
}
