export enum Permission {
    // Retailer
    VIEW_RETAILER_LIST = 'view_retailer_list',
    ADD_NEW_RETAILER = 'add_new_retailer',
    EDIT_RETAILER_INFORMATION = 'edit_retailer_information',
    EDIT_RETAILER_PHONE_NUMBER = 'edit_retailer_phone_number',
    UPDATE_RETAILER_STATUS = 'update_retailer_status',

    // Stockist
    VIEW_STOCKIST_LIST = 'view_stockist_list',
    ADD_NEW_STOCKIST = 'add_new_stockist',
    EDIT_STOCKIST_INFORMATION = 'edit_stockist_information',
    EDIT_STOCKIST_PHONE_NUMBER = 'edit_stockist_phone_number',
    UPDATE_STOCKIST_STATUS = 'update_stockist_status',
    UPDATE_PRODUCT_ON_SHLEF_STATUS = 'update_product_on_shelf_status',
    EDIT_PRODUCT_ON_SHLEF = 'edit_product_on_shelf',

    // Catalog
    VIEW_MANUFACTURER = 'view_manufacturer',
    VIEW_BRAND = 'view_brand',
    VIEW_CATEGORY = 'view_category',
    VIEW_PRODUCT_LIST = 'view_product_list',
    ADD_MANUFACTURER = 'add_manufacturer',
    ADD_BRAND = 'add_brand',
    ADD_CATEGORY = 'add_category',
    ADD_PRODUCT = 'add_product',
    EDIT_MANUFACTURER = 'edit_manufacturer',
    EDIT_BRAND = 'edit_brand',
    EDIT_CATEGORY = 'edit_category',
    EDIT_PRODUCT_LIST = 'edit_product_list',
    DELETE_MANUFACTURER = 'delete_manufacturer',
    DELETE_BRAND = 'delete_brand',
    DELETE_CATEGORY = 'delete_category',
    DELETE_PRODUCT = 'delete_product',
    UPDATE_MANUFACTURER_STATUS = 'update_manufacturer_status',
    UPDATE_BRAND_STATUS = 'update_brand_status',
    UPDATE_CATEGORY_STATUS = 'update_category_status',
    UPDATE_PRODUCT_STATUS = 'update_product_status',

    // Coupon
    VIEW_COUPON_LIST = 'view_coupon_list',
    CREATE_COUPON = 'create_coupon',
    EDIT_COUPON = 'edit_coupon',
    UPDATE_COUPON_STATUS = 'update_coupon_status',

    // Stockist Promotion
    VIEW_PROMOTION_LIST = 'view_promotion_list',
    CREATE_PROMOTION = 'create_promotion',
    EDIT_PROMOTION = 'edit_promotion',
    UPDATE_PROMOTION_STATUS = 'update_promotion_status',

    // CMS
    VIEW_BANNER_LIST = 'view_banner_list',
    VIEW_PRODUCT_SHOWCASE_LIST = 'view_product_showcase_list',
    ADD_BANNER = 'add_banner',
    ADD_PRODUCT_SHOWCASE_LIST = 'add_product_showcase_list',
    EDIT_BANNER = 'edit_banner',
    EDIT_PRODUCT_SHOWCASE_LIST = 'edit_product_showcase_list',
    UPDATE_BANNER_STATUS = 'update_banner_status',
    UPDATE_PRODUCT_SHOWCASE_STATUS = 'update_product_showcase_status',

    //  Admin
    VIEW_USER_LIST = 'view_user_list',
    ADD_USER = 'add_user',
    EDIT_USER_INFORMATION = 'edit_user_information',
    UPDATE_USER_STATUS = 'update_user_status',

    // Nofication
    VIEW_NOTIFICATION_LIST = 'view_notification_list',
    CREATE_NOTIFICATION = 'create_notification',
    EDIT_NOTIFICATION = 'edit_notification',

    // Gift
    VIEW_GIFT = 'view_gift',
    CREATE_GIFT = 'create_gift',
    EDIT_GIFT = 'edit_gift',
    UPDATE_GIFT_STATUS = 'update_gift_status',

    // Order
    VIEW_ORDER = 'view_order',
    //Billing
    VIEW_BILLING = 'view_billing',

    // Report
    VIEW_REPORT = 'view_report'
}

export enum PermissionModuleENUM {
    RETAILER = 'retailer',
    STOCKIST = 'stockist',
    PRODUCT = 'product',
    ADMIN = 'admin',
    GIFT = 'gift',
    CMS = 'cms',
    COUPON = 'coupon',
    BILLING = 'billing',
    ORDER = 'order',
    NOTIFICATION = 'notification',
    REPORT = 'report',
}
