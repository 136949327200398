/* eslint-disable @typescript-eslint/no-explicit-any */

let env: any = {};

declare const CONFIG: { string: string };
if (typeof CONFIG === 'undefined') {
    env = {
        googleMapBaseUrl: `https://maps.googleapis.com/maps/api/geocode/json?address`,
        googleKey: 'AIzaSyDUZ_MVXGacdBRH8fR5G2YwTsYa9wbsowg',
        apiUrl: 'https://admin.aura-dev.site/api',

        // apiUrl: 'http://localhost:5240',
        // homeUrl: 'http://localhost:4200',
        name: 'local environment',
        env: 'local',
        // // keycloak
        // // local
        // keycloakUrl: 'http://localhost:9001',
        // keycloakRealm: 'test',
        // keycloakClientId: 'test',
        // homeUrl: 'http://localhost:4200',

        imagePathRetailer:
            'https://aurad.blob.core.windows.net/retailer-documents/original/',
        imagePathStockist:
            'https://aurad.blob.core.windows.net/stockist-documents/original/',
        keycloakUrl: 'https://sso.odd.works',
        keycloakRealm: 'Aura',
        keycloakClientId: 'admin-web',
        homeUrl: 'https://admin.aura-dev.site',
        // homeUrl: 'http://localhost:4200',
    };
} else {
    env = CONFIG;
}

export const environment = {
    ...env,
    firebaseConfig: {
        apiKey: 'AIzaSyDCpqEhy34qKd0QZdYZHHRi_dSwN9KdPWY',
        authDomain: 'tnt-aura-dev-8cb6e.firebaseapp.com',
        projectId: 'tnt-aura-dev-8cb6e',
        storageBucket: 'tnt-aura-dev-8cb6e.appspot.com',
        messagingSenderId: '925414639265',
        appId: '1:925414639265:web:c4af039a8bcaaeaf5d52e0',
    },
};
