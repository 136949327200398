<div class="side-bar">
    <div class="logo">
        <label for="Aura">
            <img class="icon-upload" src="assets/media/WABI2b_Horizontal.svg" />
        </label>
    </div>

    <div class="menu">
        <div *ngFor="let item of menu; let i = index" class="menu-item" style="margin: 8px;"
            [class.active]="item.mainPath ? currentUrl === item.mainPath : currentUrl === item.path"
            (click)="navigateRoute(item.path)">
            <ng-container *ngIf="item.icon.endsWith('.svg'); else iconClass">
                <img [src]="item.icon" class="menu-icon" alt="{{ item.name }} Icon" style="padding-left: 12px;" />
            </ng-container>
            <ng-template #iconClass>
                <i [ngClass]="item.icon" id="{{ 'menu-item-icon-' + i }}"></i>
            </ng-template>
            <label for="{{ 'menu-item-icon-' + i }}">{{ item.name }}</label>
        </div>
    </div>
</div>
