/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DatepickerMode } from '../_enums/datepicker-mode.enum';
import { LabelMode } from '../_enums/label-mode.enum';
// import { ErrorMessage } from 'app/utils/error-message';
import { ErrorMessage } from '@modules/error-message';





import moment from 'moment';
import { GenerateRandom } from '../../../utils/rendom';
import { ConfigLabelMode } from '../_config/label.config';

import { TranslateService } from '@ngx-translate/core';
import { CustomDateAdapter } from '../../../_helpers/custom-datepicker';
import { APP_DATE_FORMATS } from '../_config/date-format-th';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    // { provide: MAT_DATE_LOCALE, useValue: 'th-TH' },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, TranslateService]
    },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DatepickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  set isDisabled(value: boolean) {

    this.disabled = value;
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input()
  set error(value: any) {
    this.isError = false;
    this.errorMessage = '';

    if (value) {
      this.isError = true;
      this.errorMessage = new ErrorMessage().getErrorMessage(value);
      // const errorKey = Object.keys(value)[0];
      // switch (errorKey) {
      //   case 'required':
      //     this.errorMessage = 'VALIDATOR.REQUIRED';
      //     return;
      //   default:
      //     this.errorMessage = 'VALIDATOR.REQUIRED';
      // }
      // console.log(this.errorMessage);
    }
  }

  @Input() name = GenerateRandom(6);
  @Input() readonly = false;
  @Input() placeholder = '';
  @Input() label = '';
  @Input() inputLabel = '';
  @Input()
  set labelMode(value: LabelMode) {
    this.mode = value;
  }
  @Input() typeMode: DatepickerMode = DatepickerMode.CALANDAR;
  @Input()
  set value(value: boolean) {

    this.form.patchValue(value);
  }
  @Input() disLastDate = '';
  @Input() disabledLabel = false


  LabelMode = LabelMode;
  DatepickerMode = DatepickerMode;
  mode: LabelMode = new ConfigLabelMode().default();
  disabled = false;
  form = new FormControl();
  subscription: Subscription = new Subscription;
  errorMessage = '';
  isRequired = false;
  isError = false;

  onChange = (value: string) => {
    return value;
  };
  onTouched = (value: string) => {
    return value;
  };

  @Output() valueChange = new EventEmitter<string>();

  ngOnInit() {
    if (window.location.href.includes("view")) {
      this.form.disable();
    }
    this.subscription = this.form.valueChanges.pipe(distinctUntilChanged()).subscribe((val) => {
      const value = this.paseDate(val);
      this.onChange(value);
      this.valueChange.emit(value);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  writeValue(value: any): void {
    // this.form.setValue(value ? new Date(value) : null);

    if (!value) {
      this.form.setValue(null, { emitEvent: false });
      return;
    }

    if (value instanceof Array) {
      const result = [];
      for (const date of value) {
        result.push(moment(date, 'DD-MM-YYYY'));
      }
      this.form.setValue(result, { emitEvent: false });
    } else {
      this.form.setValue(moment(new Date(value), 'DD-MM-YYYY'), { emitEvent: false });
    }
    if (DatepickerMode.TIMER) {
      this.form.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    return isDisabled;
  }

  paseDate(date: Moment) {
    if (date) date = moment(date);
    switch (this.typeMode) {
      case DatepickerMode.TIMER:
        return date ? date.format('HH:mm') : '';
      case DatepickerMode.CALANDAR:
        return date ? date.format('YYYY-MM-DD') : '';
      case DatepickerMode.BOTH:
        return date ? date.format('YYYY-MM-DD HH:mm') : '';
    }
  }

  onkeyDown(event: Event) {
    event.preventDefault();
  }
}
