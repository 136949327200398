import { Injectable } from '@angular/core';
import { User } from '@core/models/auth/user.model';
import { jwtDecode } from 'jwt-decode';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    getToken(): string {
        return localStorage.getItem('token') || '';
    }

    deCodeToken(): User | null {
        const token = this.getToken();

        if (!token) {
            return null;
        }
        const user = jwtDecode(token);

        return user as User;
    }

    getRoles(): string[] {
        const user = this.deCodeToken();
        return user ? user.realm_access.roles : [];
    }

    checkPermission(rolePermissions?: string[], role?: string): boolean {
        if (!rolePermissions || !role) {
            return false;
        }
        return rolePermissions.includes(role);
    }

    getUser(): User | null {
        const st = localStorage.getItem('user');
        if (st) {
            const user: User = JSON.parse(st);

            if (user.groups && user.groups.length > 0) {
                user.group = user.groups[0];
            }

            return user;
        }
        return null;
    }

    clearUser() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    }
}
