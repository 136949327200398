<div class="form-check form-switch mr-0" (click)="toggle()">
    <input
        class="form-check-input ml-2 mt-1"
        type="checkbox"
        role="switch"
        id="flexSwitchCheckChecked"
        data-onstyle="info"
        [checked]="checked"
        [disabled]="true"
    />
</div>
