import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    RejectCategory,
    SubRejectectCategory,
} from '@core/models/reject-category/category.model';
import { environment } from '@environments/environment';
import { ClearParams } from '@modules/shared/clear-params';
import { Observable, from } from 'rxjs';
import { StoreType } from '../../models/retailer/customer-type.model';
import {
    Retailer,
    RetailerDatatable,
    RetailerParams,
    uploadRetailer,
} from '../../models/retailer/retailer.model';
// import axios, { AxiosResponse } from 'axios';
import { ApproveCategory } from '@core/models/approve-category/category.model';
import axios from 'axios';

@Injectable({
    providedIn: 'root',
})
export class RetailerService {
    baseURL = `${environment.apiUrl}/customer/retailers`;
    constructor(private http: HttpClient) { }

    getList(params: RetailerParams): Observable<RetailerDatatable> {
        return this.http.get<RetailerDatatable>(this.baseURL, {
            params: ClearParams(params),
        });
    }

    get(id: number): Observable<Retailer> {
        return this.http.get<Retailer>(this.baseURL + '/' + id);
    }

    create(params: Retailer): Observable<Retailer> {
        return this.http.post<Retailer>(this.baseURL, params);
    }

    update(id: number, params = {}): Observable<Retailer> {
        return this.http.patch<Retailer>(this.baseURL, {
            id: id,
            ...params,
        });
    }

    updateDocument(id: number, params = {}): Observable<Retailer> {
        return this.http.patch<Retailer>(
            this.baseURL + '/' + id + '/documents',
            params,
        );
    }

    getStoreType(filterCode: string): Observable<StoreType> {
        return this.http.get<StoreType>(
            environment.apiUrl + '/customer/store-type/' + filterCode,
        );
    }

    getRejectCategory(): Observable<RejectCategory[]> {
        return this.http.get<RejectCategory[]>(
            environment.apiUrl + '/customer/RejectCategories',
        );
    }

    getSubRejectCategory(
        categoryId: number,
    ): Observable<SubRejectectCategory[]> {
        if (categoryId != null) {
            return this.http.get<SubRejectectCategory[]>(
                environment.apiUrl + '/customer/RejectCategories/' + categoryId,
            );
        }
        return this.http.get<SubRejectectCategory[]>(
            environment.apiUrl + '/customer/RejectCategories',
        );
    }

    getApproveCategory(): Observable<ApproveCategory[]> {
        return this.http.get<ApproveCategory[]>(
            environment.apiUrl + '/customer/ApproveCategories',
        );
    }

    validateModilePhoneNumber(phoneNumber: string): Observable<boolean> {
        return this.http.get<boolean>(
            `${environment.apiUrl}/customer/duplicate-phone`,
            {
                params: { phoneNumber: phoneNumber },
            },
        );
    }

    uploadDocument(value: FormData): Observable<uploadRetailer> {
        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('token')
        };

        return from(
            axios.post(
                environment.apiUrl + '/customer/retailers/upload',
                value,
                { headers },
            ),
        );
    }

    // rejectRetailer(id: number, params = {}): Observable<APIResponse> {
    //     return this.http.patch<APIResponse>(`${environment.apiUrl}/customer`, {
    //         id: id,
    //         ...params,
    //     });
    // }
}
