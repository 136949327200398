<div  class="d-flex justify-content-end" style="width: 100% !important;" >
    <button type="button" data-cy="cross-close" class="btn-close ms-auto mt-2" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-container ps-1 pe-1 d-flex justify-content-center flex-column">
  <div data-cy="modal-content" class=" text-center mb-3">
    <h5 id="titlePopupConfirm" class="modal-title header-style text-center header-bold mb-2">
      {{ modalConfig.title }}
    </h5>
  </div>

  <div data-cy="modal-content" class=" text-center mb-1">
    <p class="message-text subtitle-text">{{ modalConfig.subtitle }}</p>
  </div>

  <div data-cy="modal-content" class=" text-center mb-2">
    <p class="message-text">{{ modalConfig.message }}</p>
  </div>

  <div data-cy="modal-footer" class="d-flex justify-content-between ps-1 pe-1 mb-1" *ngIf="modalConfig.cancelLabel && modalConfig.confirmLabel">
    <div class="d-flex align-items-center w-100">
      <button type="button" data-cy="button-close" class="btn custom-width  py-3 px-5 p-md-2 mr-2" [ngClass]="getOutlineButtonClasses()" (click)="close()">
        {{ modalConfig.cancelLabel }}
      </button>
    </div>
    <div class="d-flex align-items-center w-100">
      <button type="button" data-cy="button-confirm" class="btn custom-width py-3 px-5 p-md-2 ml-2" [ngClass]="getButtonClasses()" (click)="confirm()">
        {{ modalConfig.confirmLabel }}
      </button>
    </div>
  </div>


    <div data-cy="modal-footer" align="center" class="d-flex justify-content-center ps-1 pe-1 mb-1" *ngIf="modalConfig.cancelLabel && !modalConfig.confirmLabel">
        <div class="w-100">
            <button type="button" data-cy="button-close" class="btn custom-width  py-3 px-5 p-md-2 " [ngClass]="getButtonErrorConfirm()" (click)="close()">
                {{ modalConfig.cancelLabel }}
            </button>
        </div>
    </div>
</div>
