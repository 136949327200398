export enum group {
    eSD = 'eSD',
    RD = 'RD',
    eBD = 'eBD',
    eRDM = 'eRDM',
    eRM = 'eRM',
    Admin = 'Outlet Master Controller - eB2B',
    CSM = 'Customer Satisfaction Process Manager',
    CSA = 'Customer Satisfaction Process Analyst',
    CS = 'Customer Service Agent',
    DXM = 'Digital Experience Manager',
    Director = 'Commercial Innovation Strategies Director',
    BI_Analyst = 'eB2B Business Intelligence Analyst',
    CM = 'eB2B Commercial Manager',
    CXM = 'eB2B Customer Experience Manager',
    oa = 'OA',
    // eSD = 'eSD',
}
