<div class="d-flex align-items-start pb-3">
    <div *ngIf="!image_Logo_Url[0]">
        <div class="card-container">
            <div class="card" (click)="onClick()">
                <input
                    id="image-input"
                    #imageInput
                    type="file"
                    name="image"
                    accept="image/*"
                    [multiple]="mutiple"
                    [hidden]="true"
                    (change)="processFiles(imageInput)"
                />
                <div class="align-items-center upload">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                </div>
                <label class="align-items-center text-upload">{{
                    "ACTION.ADD_FILE" | translate
                }}</label>
            </div>
        </div>
    </div>
    <div class="card-container-image" *ngIf="image_Logo_Url[0]">
        <div class="card-image">
            <div class="backgroundImg">
                <div class="del" [ngClass]="{'bg-transparent': isDisableDelete}"  (click)="removeImage()">
                    <img
                        width="22"
                        height="22"
                        src="assets/icons/SVG/Trash.svg"
                        *ngIf="!isDisableDelete"
                    />
                </div>
                <ng-container *ngIf="!haveErrorFile && !haveErrorType && !haveErrorSize">
                    <div class="img-container">
                        <img
                            class="img-upload"
                            [src]="image_Logo_Url[0]"
                            alt=""
                        />
                    </div>
                </ng-container>

                <ng-container *ngIf="
                            haveErrorSize ||
                            haveErrorType ||
                            haveErrorFile
                    ">
                    <div class="d-flex justify-center">
                        <i class="fa fa-file-upload icon-upload" aria-hidden="true"></i>
                    </div>

                    <div class="is-invalid-img">
                        <br />
                        <i ngClass="fa fa-exclamation-circle mr-1" *ngIf="
                                haveErrorSize
                            "></i>
                        <a *ngIf="
                                haveErrorSize
                            ">{{ error_size }}</a>
                        <i ngClass="fa fa-exclamation-circle mr-1" *ngIf="
                                haveErrorType
                            "></i>
                        <a *ngIf="
                                haveErrorType
                            ">{{ error_type }}</a>
                        <i ngClass="fa fa-exclamation-circle mr-1" *ngIf="
                                haveErrorFile
                            "></i>
                        <a *ngIf="
                                haveErrorFile
                            ">{{ file_error }}</a>
                    </div>
                </ng-container>
            </div>
            <ng-container>
                <p class="text-img-name-loading">
                    {{imageName}}
                    <!-- {{
                        imagesFormLogo.value.photo
                            .name
                    }} -->
                </p>
                <!-- <p class="text-img-size-loading">
                    {{
                    (
                    imagesFormLogo.value
                    .photo.size /
                    1024 /
                    1024
                    ).toFixed(2)
                    }}
                    MB
                </p> -->
            </ng-container>
        </div>
    </div>
</div>
