/* eslint-disable @typescript-eslint/no-explicit-any */

import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { from } from 'rxjs';
import { UserService } from '../services/auth/user.service';
// import { environment } from '@environments/environment';
// import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor(
        private userService: UserService,
        private translate: TranslateService,
        // private keycloak: KeycloakService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        // const token = localStorage.getItem('token');
        // if (!token) {
        //     const exceptUrls = [
        //         'api/customer/retailers',
        //         'api/customer/RejectCategories',
        //         'api/customer/ApproveCategories',
        //         'api/customer/duplicate-phone',
        //         'api/customer/retailers/upload',
        //         'api/customer/stockists',
        //         'api/customer/duplicate-phone/stockist',
        //         'api/customer/stockists/upload',
        //         'api/product/stockist-products/managedshelf',
        //         'api/customer/orders',
        //         'api/customer/billings',
        //         'api/customer/billings/total',
        //         'api/customer/billings/stockist',
        //         'api/product/brands',
        //         'api/product/category',
        //         'api/customer/coupons',
        //         'api/product/gifts',
        //         'api/customer/orders',
        //         'api/product/products',
        //         'api/product/upload',
        //         'api/history',
        //         'api/promotion/promotions',
        //         'api/product/stockist',
        //         'api/product/stockist',
        //         'api/customer/stockists',
        //         'api/customer/duplicate-phone/stockist',
        //         'api/customer/stockists/upload',
        //         'api/customer/stockists/Change/logo',
        //         'api/customer/stockists/Delete/logo',
        //         'api/product/stockist-products/managedshelf',
        //         'api/customer/thai-province',
        //         'api/admin/users',
        //         'api/admin/duplicate-email',
        //         'api/admin/duplicate-email',
        //     ];

        //     if (!exceptUrls.some((url) => req.url.includes(url))) {
        //         this.userService.clearUser();
        //         this.keycloak.logout(environment.homeUrl);
        //         return throwError(
        //             () => new Error('Token not found, please login again')
        //         );
        //     }
        // }

        return from(this.handle(req, next));
    }

    handle(req: HttpRequest<any>, next: HttpHandler) {
        const user = this.userService.getUser();
        const token = localStorage.getItem('token');
        if (user) {
            const modifiedReq = req.clone({
                setHeaders: {
                    'locale': this.translate.getBrowserLang() || 'th',
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            })
            return next.handle(modifiedReq);
        }

        return next.handle(req);
    }
}
