export enum ModalState {
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
    Info = 'info',
    Confirm = 'confirm',
}

export enum ButtonTag {
    Primary = 'primary',
    Secondary = 'secondary',
    Success = 'success',
    Danger = 'danger',
    Warning = 'warning',
    Info = 'info',
    Light = 'light',
    Dark = 'dark',
    Change = 'change'
}

export enum DocumentUploadType {
    IDCard = 0,
    LiquorLicense = 1,
    TobaccoLicense = 2,
    StorePicture = 3,
    PORPOR20 = 4,
    Logo = 5,
}
