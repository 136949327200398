<div class="upload">
  <div class="preview" [class.preview-uploaded]="hasUploadedImages" (click)="onClick()"
    [ngStyle]="{'width': width, 'height': height}">
    <input #imageInput [id]="id || 'image-input'" type="file" name="image" accept="image/*" [hidden]="true"
      [attr.multiple]="multiple ? '' : null" (change)="processFile(imageInput)" />

    <!-- Container for image previews -->
    <div class="image-previews">
      <!-- Empty preview placeholder -->
      <div class="empty-preview"></div>

      <!-- Uploaded images -->
      <ng-container *ngFor="let imageUrl of ensureArray(imageUrl$ | async)">
        <img [src]="imageUrl" class="img-responsive img-fit-cover" alt="Uploaded image">
      </ng-container>
    </div>

    <!-- Upload Prompt shown when there are no images -->
    <div *ngIf="(imageUrl$ | async)?.length === 0" class="upload-prompt">
      <div class="upload-icon">
        <i class="la la-cloud-upload"></i>
      </div>
      <div class="upload-text">
        {{ 'เพิ่มไฟล์' }}
      </div>
    </div>
  </div>

  <!-- Remove Image Icon, shown when there are images -->
  <span *ngIf="ensureArray(imageUrl$ | async).length > 0 && removable" class="remove-image-icon"
    (click)="removeImage()">
    <i class="fa fa-times-circle text-danger"></i>
  </span>
</div>