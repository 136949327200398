import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductData } from '@core/models/product/product';
import {
    IPayloadStockistProductBulk,
    StockistProduct,
    StockistProductDatatable,
    StockistProductParams,
    StockistProductPayload,
} from '@core/models/stockist-product/stockist-product.model';
import { environment } from '@environments/environment';
import { ClearParams } from '@modules/shared/clear-params';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StockistProductsService {
    baseURL = `${environment.apiUrl}/product/stockist`;
    constructor(private http: HttpClient) {}

    getList(
        id: number,
        params: StockistProductParams,
    ): Observable<StockistProductDatatable> {
        return this.http.get<StockistProductDatatable>(
            this.baseURL + '/' + id + '/products',
            {
                params: ClearParams(params),
            },
        );
    }

    create(
        id: number,
        params: StockistProductPayload,
    ): Observable<StockistProductPayload> {
        return this.http.post<StockistProductPayload>(
            this.baseURL + '/' + id + '/product',
            params,
        );
    }

    update(
        id: number,
        productID: number,
        params: StockistProductPayload,
    ): Observable<StockistProductPayload> {
        return this.http.patch<StockistProductPayload>(
            this.baseURL + '/' + id + '/product/' + productID,
            params,
        );
    }

    updateStatus(id: number, status: string): Observable<ProductData> {
        return this.http.patch<ProductData>(
            this.baseURL + `/${id}/${status}`,
            undefined,
        );
    }

    createUploadBulk(id:number,listData:IPayloadStockistProductBulk[]){
        return this.http.post(this.baseURL + `/${id}/products/bulk`,listData)
    }

    exportFile(id:number): Observable<StockistProduct[]>{
        return this.http.get<StockistProduct[]>(this.baseURL + `/${id}/products/export`)
    }
}
