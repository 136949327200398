<div class="d-flex align-items-start pb-3">
    <div *ngIf="image_Url.length < max">
        <div class="card-container">
            <div class="card" (click)="onClick()">
                <input
                    id="{{idElement ? idElement : 'image-input-multi'}}"
                    #imageInput
                    type="file"
                    name="image"
                    accept="image/*"
                    multiple
                    [hidden]="true"
                    (change)="processFiles(imageInput)"
                />
                <div class="align-items-center upload">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                </div>
                <label class="align-items-center text-upload">{{
                    "ACTION.ADD_FILE" | translate
                }}</label>
            </div>
        </div>
        <div class="is-invalid-img">
            <i ngClass="fa fa-exclamation-circle mr-1" *ngIf="haveErrorMax || haveErrorMin
        "></i>
            <a *ngIf="haveErrorMax">{{error_max}}</a>
            <a *ngIf="haveErrorMin">{{error_min}}</a>
        </div>
    </div>

    <div *ngFor="let imagesForm of image_Url; let i = index">
        <div class="card-container-image" *ngIf="image_Url[i]">
            <div class="card-image">
                <div class="backgroundImg">
                    <div class="del" [ngClass]="{'bg-transparent': isDisabled}" (click)="removeImage(i,imagesForm)">
                        <img
                            width="22"
                            height="22"
                            src="assets/icons/SVG/Trash.svg"
                            *ngIf="!isDisabled"
                        />
                    </div>
                    <ng-container *ngIf="!haveErrorFile[i] && !haveErrorType[i] && !haveErrorSize[i]">
                        <div class="img-container">
                            <img
                                class="img-upload"
                                [src]="image_Url[i]"
                                alt=""
                            />
                        </div>
                    </ng-container>

                    <ng-container *ngIf="
                                haveErrorSize[i] ||
                                haveErrorType[i] ||
                                haveErrorFile[i]
                        ">
                        <div class="d-flex justify-center">
                            <i class="fa fa-file-upload icon-upload" aria-hidden="true"></i>
                        </div>

                        <div class="is-invalid-img">
                            <br />
                            <i ngClass="fa fa-exclamation-circle mr-1" *ngIf="
                                    haveErrorSize[i]
                                "></i>
                            <a *ngIf="
                                    haveErrorSize[i]
                                ">{{ error_size }}</a>
                            <i ngClass="fa fa-exclamation-circle mr-1" *ngIf="
                                    haveErrorType[i]
                                "></i>
                            <a *ngIf="
                                    haveErrorType[i]
                                ">{{ error_type }}</a>
                            <i ngClass="fa fa-exclamation-circle mr-1" *ngIf="
                                    haveErrorFile[i]
                                "></i>
                            <a *ngIf="
                                    haveErrorFile[i]
                                ">{{ file_error }}</a>
                        </div>
                    </ng-container>
                </div>
                <ng-container>
                    <p class="text-img-name-loading">
                        {{imageName}}
                        <!-- {{
                            imagesFormLogo.value.photo
                                .name
                        }} -->
                    </p>
                    <!-- <p class="text-img-size-loading">
                        {{
                        (
                        imagesFormLogo.value
                        .photo.size /
                        1024 /
                        1024
                        ).toFixed(2)
                        }}
                        MB
                    </p> -->
                </ng-container>
            </div>
        </div>
    </div>
</div>
