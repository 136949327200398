/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalMessageComponent } from '../modal-message/modal-message.component';
import { ButtonTag } from '@core/models/shares/enum';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export interface DeleteImage {
    index: number
    url: string
}

@Component({
    selector: 'app-input-image-muti',
    templateUrl: './input-image-muti.component.html',
    styleUrls: ['./input-image-muti.component.scss'],
})

export class InputImageMutiComponent {
    modalRef?: NgbModalRef;
    image_Url$ = new BehaviorSubject<ArrayBuffer | null>(null);
    image_Url: string[] = [];
    @Input() set currentData(value: string[]) {
        this.image_Url = value;
        value.map(() => {
            this.haveErrorSize.push(false);
            this.haveErrorType.push(false);
        });
    }
    @Input() imageName = '';
    @Input() idElement = null;
    @Input() min = 0
    @Input() max = 5
    @Input() isDisabled = false
    @Output() imageChange: EventEmitter<File | undefined> = new EventEmitter<
        File | undefined
    >();
    @Output() deleteImage: EventEmitter<DeleteImage> = new EventEmitter<DeleteImage>();

    constructor(
        private modalService: NgbModal,
        private translate: TranslateService
    ) { }

    error_size = this.translate.instant('VALIDATOR.SIZE_FILE');
    error_type = this.translate.instant('VALIDATOR.TYPE_FILE');
    file_error = this.translate.instant('VALIDATOR.UPLOAD_FILE');
    error_min = this.translate.instant('VALIDATOR.LIMIT_MIN') + this.min + this.translate.instant('VALIDATOR.UNIT_IMAGE')
    error_max = this.translate.instant("VALIDATOR.LIMIT_MAX") + this.max + this.translate.instant('VALIDATOR.UNIT_IMAGE')

    haveErrorSize: boolean[] = [];
    haveErrorType: boolean[] = [];
    haveErrorFile: boolean[] = [];
    haveErrorMin = false;
    haveErrorMax = false


    async removeImage(index: number, url: string) {
        const result = await this.openDeleteModal();
        if (!result) {
            return;
        }

        this.image_Url.splice(index, 1);
        this.haveErrorSize.splice(index, 1);
        this.haveErrorType.splice(index, 1);

        this.deleteImage.emit({ index, url });
    }

    processFiles(imageInput: any) {
        this.haveErrorMax = false
        this.haveErrorMin = false
        const files: FileList = imageInput.files;
        const selected = files;
        if (selected.length + this.image_Url.length > this.min) {
            if (selected.length + this.image_Url.length > this.max) {
                this.haveErrorMax = true
                return
            }
            for (let i = 0; i < selected.length; i++) {
                const acceptedFileTypes = [
                    'image/png',
                    'image/jpg',
                    'image/jpeg',
                ];
                const maxFileSize = 5 * 1024 * 1024;
                this.haveErrorSize.push(false);
                this.haveErrorType.push(false);
                if (selected[i].size > maxFileSize) {
                    this.haveErrorSize[this.haveErrorSize.length - 1] = true;
                } else {
                    this.haveErrorSize[this.haveErrorSize.length - 1] = false;
                }

                if (!acceptedFileTypes.includes(selected[i].type)) {
                    this.haveErrorType[this.haveErrorSize.length - 1] = true;
                } else {
                    this.haveErrorType[this.haveErrorSize.length - 1] = false;
                }

                const reader = new FileReader();
                reader.onload = () => {
                    if (reader.result) {
                        const arrayBuffer = reader.result as ArrayBuffer;
                        this.image_Url$.next(arrayBuffer);
                        const valueToAdd = this.image_Url$.value || '';
                        this.image_Url.push(valueToAdd.toString());
                    }
                };
                reader.readAsDataURL(selected[i]);
                if (
                    !this.haveErrorSize[this.haveErrorSize.length - 1] &&
                    !this.haveErrorType[this.haveErrorSize.length - 1]
                ) {
                    this.imageChange.emit(selected[i]);
                }
            }
        } else {
            this.haveErrorMin = true
        }
    }

    onClick() {
        const elementId_old = this.idElement
            ? this.idElement
            : 'image-input-multi';
        const element_old = document.getElementById(elementId_old.toString());
        this.clearFileInput(element_old);
        const elementId = this.idElement ? this.idElement : 'image-input-multi';
        const element = document.getElementById(elementId.toString());
        if (element) {
            element.click();
        } else {
            console.error(`Element with ID ${elementId} not found.`);
        }
    }

    async openDeleteModal() {
        this.modalRef = this.modalService.open(ModalMessageComponent, {
            centered: true,
        });
        this.modalRef.componentInstance.modalConfig = {
            colorButton: ButtonTag.Info,
            title: this.translate.instant('MODAL_IMAGE.TITLE'),
            message: this.translate.instant('MODAL_IMAGE.MESSAGE'),
            confirmLabel: this.translate.instant('MODAL_IMAGE.CONFIRM'),
            cancelLabel: this.translate.instant('MODAL_IMAGE.CANCEL'),
        };
        const confirm: boolean = await this.modalRef.result;
        return confirm;
    }

    clearFileInput(imageInput: any) {
        imageInput.value = '';
    }
}
