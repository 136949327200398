import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CategoryBannerLevel } from '@core/models/banner/banner.model';

@Component({
  selector: 'app-select-muli-category',
  templateUrl: './select-muli-category.component.html',
  styleUrls: ['./select-muli-category.component.scss']
})
export class SelectMuliCategoryComponent {
  @Input() label = ''
  @Input() error: unknown
  @Input() oldData: CategoryBannerLevel = { level1Ids: [], level2Ids: [], level3Ids: [] }
  @Output() formCategory: EventEmitter<CategoryBannerLevel> = new EventEmitter<CategoryBannerLevel>;
  @Input() isDisabled = false;
  onSelectCategory(value: CategoryBannerLevel) {
    this.formCategory.emit(value)
  }
}
