import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { PermissionService } from '@core/services/shared/permission.service';
import { PermissionModuleENUM } from '@utils/permission.enum';

export const billingGuard: CanActivateFn = async (route, state) => {
    const permissionService = inject(PermissionService);
    return await permissionService.checkPermission(
        state.url,
        PermissionModuleENUM.BILLING,
    );
};
