<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="inputContent"></ng-container>
</ng-container>

<ng-template #default>
  <div class="form-group validated">
    <label class="form-control-label" [for]="name">{{ label | translate  }}</label>
    <ng-container [ngTemplateOutlet]="inputContent"></ng-container>
  </div>
</ng-template>

<ng-template #inline>
  <div class="form-group row validated">
    <div class="col-md-12 col-sm-12">
      <ng-container [ngTemplateOutlet]="inputContent"></ng-container>
      <label  class="input-inline-label form-control-label" [for]="name">{{ label | translate  }}</label>
    </div>
  </div>
</ng-template>

<ng-template #inputContent>
  <mat-checkbox class="custom-checkbox" [formControl]="form">{{ inputLabel | translate  }}</mat-checkbox>
  <div class="invalid-feedback">{{ errorMessage | translate  }}</div>
</ng-template>
