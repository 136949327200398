import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Permission } from '@core/models/permission/permission.model';
import { UserService } from '@core/services/auth/user.service';
import { PermissionService } from '@core/services/shared/permission.service';
import { Permission as PermissionENUM } from '@utils/permission.enum';
import { jwtDecode } from 'jwt-decode';

import { KeycloakService } from 'keycloak-angular';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    constructor(
        private route: Router,
        private keycloakService: KeycloakService,
        private userService: UserService,
        private permissionService: PermissionService,
        private jwthelp: JwtHelperService

    ) { }

    async ngOnInit() {
        const isLoggedIn = await this.keycloakService.isLoggedIn();
        const token = localStorage.getItem("token")
        const isExpired = this.jwthelp.isTokenExpired(token)

        if (!isExpired) {
            this.checkChangPath();
        }

        else if (isLoggedIn) {
            this.keycloakService.getToken().then((token) => {
                localStorage.setItem('token', token);
                localStorage.setItem(
                    'user',
                    JSON.stringify(jwtDecode(token)),
                );
                this.checkChangPath();
            });
        } else {
            this.keycloakService.login();
        }
    }

    async checkChangPath() {
        const permissions = this.permissionService
            .getPermissions()
            .filter((item) => {
                return item.isList;
            })
            .map((item) => {
                return item.permissions[0];
            });

        const user = this.userService.deCodeToken();
        const temp = user?.realm_access.roles.filter((role) => {
            return permissions.includes(role as PermissionENUM);
        });
        // console.log(temp);

        if (temp) {
            let filterPermissions: Permission[] = [];
            temp.forEach((element) => {
                const permission = this.permissionService.getPermissionWithkey(
                    element as PermissionENUM,
                    true,
                );
                if (permission) {
                    filterPermissions.push(permission);
                }
            });

            filterPermissions = filterPermissions.sort(
                (a, b) => a.order - b.order,
            );
            if (filterPermissions.length > 0) {
                const firstPermission = filterPermissions[0];
                if (firstPermission.url) {
                    this.route.navigate([`${firstPermission.url}`]);
                } else {
                    await this.permissionService.authenFaild();
                }
            } else {
                await this.permissionService.authenFaild();
            }
            // else if (user.group === group.eSD) {
            //     this.route.navigate(['order']);
            // }
        }
    }
}
