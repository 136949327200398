/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Input, OnDestroy, forwardRef, ViewEncapsulation } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { LabelMode } from '../_enums/label-mode.enum';
import { ConfigLabelMode } from '../_config/label.config';
import { ErrorMessage } from '@modules/error-message';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFileComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class InputFileComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
  @Input()
  set textGroup(value: string) {
    this.isInputGroup = true;
    this.inputGroupMessage = value;
  }

  @Input()
  set iconGroup(value: string) {
    this.isInputGroupIcon = true;
    this.inputGroupIcon = value;
  }

  @Input()
  set error(value: any) {
    this.isError = false;
    this.errorMessage = '';

    if (value) {
      this.isError = true;
      this.errorMessage = new ErrorMessage().getErrorMessage(value);
    }
  }

  @Input() name = '';
  @Input() placeholder = '';
  @Input() type = '';
  @Input() readonly = false;
  @Input() label = '';
  @Input() customMask = '';
  @Input() max = 0;
  @Input() min = 0;
  @Input()
  set labelMode(value: LabelMode) {
    this.mode = value;
  }

  LabelMode = LabelMode;
  mode: LabelMode = new ConfigLabelMode().default();

  form = new FormControl();
  isInputGroup = false;
  isInputGroupIcon = false;
  inputGroupMessage = '';
  inputGroupIcon = '';
  subscription: Subscription = new Subscription;
  errorMessage = '';
  isRequired = false;
  isError = false;

  onChange = (value: string) => {
    return value;
  };
  onTouched = (value: string) => {
    return value;

  };

  ngOnInit() {
    if (!this.type) {
      this.type = 'text';
    }

    this.subscription = this.form.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      let value = this.form.value;
      if (typeof this.form.value === 'string') {
        value = this.form.value.trim();
      }
      if (this.max && value > this.max) {
        value = this.max;
        setTimeout(() => {
          this.form.patchValue(value, { emitEvent: false });
        });
      }
      if (this.min && value < this.min) {
        value = this.min;
        setTimeout(() => {
          this.form.patchValue(value, { emitEvent: false });
        });
      }
      this.onChange(value);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  writeValue(value: any): void {
    this.form.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    return isDisabled;
  }
}
