import { Component, Input } from '@angular/core';
import { ButtonTag } from '@core/models/shares/enum';
import { ModalConfig } from '@core/models/shares/modal.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modal-message',
    templateUrl: './modal-message.component.html',
    styleUrls: ['./modal-message.component.scss'],
})
export class ModalMessageComponent {
    @Input() modalConfig: ModalConfig;

    constructor(private modalRef: NgbActiveModal) {
        this.modalConfig = {
            colorButton: ButtonTag.Primary,
            title: '',
            subtitle: '',
            category_id: 0,
            category_name: '',
            message: '',
            confirmLabel: '',
            cancelLabel: '',
        };
    }

    close() {
        this.modalRef.close(false);
    }

    confirm() {
        this.modalRef.close(true);
    }

    getButtonClasses(): string {
        switch (this.modalConfig.colorButton) {
            case ButtonTag.Success:
                return 'btn-success';
            case ButtonTag.Danger:
                return 'btn-danger';
            case ButtonTag.Warning:
                return 'btn-warning';
            case ButtonTag.Info:
                return 'custom-danger';
            case ButtonTag.Dark:
                return 'btn-dark';
            case ButtonTag.Light:
                return 'btn-light';
            case ButtonTag.Primary:
                return 'btn-primary';
            case ButtonTag.Secondary:
                return 'btn-secondary';
            case ButtonTag.Change:
                return 'custom-confrim';
        }
    }

    getOutlineButtonClasses(): string {
        switch (this.modalConfig.colorButton) {
            case ButtonTag.Success:
                return 'custom-info';
            case ButtonTag.Danger:
                return 'btn-outline-danger text-danger';
            case ButtonTag.Warning:
                return 'btn-outline-warning text-warning';
            case ButtonTag.Info:
                return 'custom-info';
            case ButtonTag.Dark:
                return 'btn-outline-dark text-dark';
            case ButtonTag.Light:
                return 'btn-outline-light text-secondary';
            case ButtonTag.Primary:
                return 'btn-outline-primary text-primary';
            case ButtonTag.Secondary:
                return 'btn-outline-secondary text-secondary';
            case ButtonTag.Change:
                return 'custom-info';
        }
    }

    getButtonErrorConfirm(): string {
        switch (this.modalConfig.colorButton) {
            case ButtonTag.Success:
                return 'custom-error-confrim';
            case ButtonTag.Danger:
                return 'btn-outline-danger text-danger';
            case ButtonTag.Warning:
                return 'btn-outline-warning text-warning';
            case ButtonTag.Info:
                return 'custom-error-confrim';
            case ButtonTag.Dark:
                return 'btn-outline-dark text-dark';
            case ButtonTag.Light:
                return 'btn-outline-light text-secondary';
            case ButtonTag.Primary:
                return 'btn-outline-primary text-primary';
            case ButtonTag.Secondary:
                return 'btn-outline-secondary text-secondary';
            case ButtonTag.Change:
                return 'custom-confrim';
        }
    }
}
