<div class="nav-bar-container">
    <div class="notification-icon">
        <!-- <i class="fa-regular fa-bell"></i> -->
    </div>
    <div class="separate-stick"></div>
    <div class="profile-container">
        <!-- <div class="profile">
            <i class="fa-regular fa-user"></i>
        </div> -->
        <div class="detail">
            <div class="email-address">{{ textEmail }}</div>
            <div class="member-role">{{ textGroup }}</div>
        </div>
        <div class="dropdown" (click)="toggleDropdown()">
            <i class="{{ getDropdownIconClass() }}"></i>
            <ul *ngIf="isOpen" class="dropdown-menu">
                <li *ngFor="let item of dropdownMenu" (click)="isOpen = false; navigateTo()">
                    <a style="color: gray">
                        <i class="{{ item.icon }}"></i>
                        {{ item.name }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>