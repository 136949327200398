import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent {
  @Input() checked?: boolean;
  @Input() id?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() Toggle = new EventEmitter<any>();



  toggle(): void {
    this.Toggle.emit(this.id);
  }
}