
<div class="card-container" >
    <div class="card" (click)=" !disabled ? onClick() : null ">
        <input
            #imageInput
            [id]="id || 'image-input'"
            type="file"
            name="image"
            accept="image/*"
            multiple
            [hidden]="true"
            [disabled]="disabled"
            (change)="processFile(imageInput)"
        />
        <div class="align-items-center upload">
            <i class="fa fa-plus" aria-hidden="true"></i>
        </div>
        <label class="align-items-center text-upload">{{ 'ACTION.ADD_FILE' | translate }}</label>
    </div>
</div>

<!-- <div class="card-container-disabled" *ngIf="disabled" >
    <div class="card-disabled" >
        <input
            #imageInput
            [id]="id || 'image-input'"
            type="file"
            name="image"
            accept="image/*"
            multiple
            [hidden]="true"
            (change)="processFile(imageInput)"
        />
        <div class="align-items-center upload-disabled">
            <i class="fa fa-plus" aria-hidden="true"></i>
        </div>
        <label class="align-items-center text-upload-disabled">{{ 'ACTION.ADD_FILE' | translate }}</label>
    </div>
</div> -->
