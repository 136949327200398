<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="inputContent"></ng-container>
</ng-container>

<ng-template #default>
  <div class="form-group validated">
    <label class="form-control-label" [for]="name">{{ label | translate }}</label>
    <ng-container [ngTemplateOutlet]="inputContent"></ng-container>
  </div>
</ng-template>

<ng-template #inline>
  <div class="form-group row validated">
    <label class="col-md-3 col-sm-12 input-inline-label form-control-label" [for]="name">{{ label | translate }}</label>
    <div class="col-md-9 col-sm-12">
      <ng-container [ngTemplateOutlet]="inputContent"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #inputContent>
  <ng-container *ngIf="isInputGroup || isInputGroupIcon; then inputGroup; else inputDefault"></ng-container>
</ng-template>

<ng-template #input>
  <input
    class="form-control input-price-text "
    [ngClass]="{ 'is-invalid': isError }"
    [id]="name"
    [name]="name"
    [placeholder]="placeholder | translate"
    [type]="type"
    [readonly]="readonly"
    [formControl]="form"
    [mask]="customMask"
    [thousandSeparator]="thousandSeparator"
    [maxLength]="maxLength"
    [min]="min"
    [max]="max"
    (focus)="onInputPriceTextFocus()"
    (blur)="onInputPriceTextBlur()"
    autocomplete="off"
  />
</ng-template>

<ng-template #inputGroup>
  <div class="input-group">
    <div class="input-group-append ">
        <span [ngClass]="{'input-group-text-is-invalid': isError, 'input-group-text': !isInputPriceTextFocus, 'input-group-text-focus': isInputPriceTextFocus, 'input-group-text-disable': isDisabled}">
          <span *ngIf="isInputGroup">{{ inputGroupMessage | translate }}</span>
          <i *ngIf="isInputGroupIcon" [ngClass]="inputGroupIcon"></i>
        </span>
    </div>
    <ng-container [ngTemplateOutlet]="input"></ng-container>

    <div class="invalid-feedback">{{ errorMessage | translate }}</div>
  </div>
  <!-- <span class="form-text text-muted">We'll never share your email with anyone else.</span> -->
</ng-template>

<ng-template #inputDefault>
  <ng-container [ngTemplateOutlet]="input"></ng-container>
  <div class="invalid-feedback" *ngIf="minLangthError === 0 && maxLengthError === 0">{{ errorMessage | translate }}</div>
  <div class="invalid-feedback" *ngIf="minLangthError !== 0">{{ 'VALIDATOR.MIN_LENGTH' | translate }} {{ minLangthError }} {{ 'VALIDATOR.LENGTH_VAR' | translate }}</div>
  <div class="invalid-feedback" *ngIf="maxLengthError !== 0">{{ 'VALIDATOR.MAX_LENGTH' | translate }} {{ maxLengthError }} {{ 'VALIDATOR.LENGTH_VAR' | translate }}</div>

</ng-template>
