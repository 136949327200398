/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Component,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ErrorMessage } from '@modules/error-message';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ConfigLabelMode } from '../_config/label.config';
import { LabelMode } from '../_enums/label-mode.enum';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class InputComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input()
    set isDisabled(value: boolean) {

        if (value) {
            this.form.disable();
        }
        else {
            this.form.enable();
        }
    }
    @Input()
    set textGroup(value: string) {
        this.isInputGroup = true;
        this.inputGroupMessage = value;
    }

    @Input()
    set iconGroup(value: string) {
        this.isInputGroupIcon = true;
        this.inputGroupIcon = value;
    }

    @Input()
    set error(value: any) {
        this.isError = false;
        this.errorMessage = '';
        this.maxLengthError = 0;
        this.minLangthError = 0;

        if (value) {
            this.isError = true;
            this.errorMessage = new ErrorMessage().getErrorMessage(value);

            if (value.minlength) {
                this.minLangthError = value.minlength.requiredLength;
            } else if (value.maxlength) {
                this.maxLengthError = value.maxlength.requiredLength;
            }
            if (value.min) {
                this.minLangthError = value.min.min;
            } else if (value.max) {
                this.maxLengthError = value.max.max;
            }
        }
    }

    @Input() name = '';
    @Input() placeholder = '';
    @Input() type = '';
    @Input() readonly = false;
    @Input() label = '';
    @Input() customMask = '';
    @Input() max?: number;
    @Input() min?: number;
    @Input() maxLength: number | null = null;
    @Input() thousandSeparator = '';
    @Input()
    set labelMode(value: LabelMode) {
        this.mode = value;
    }

    LabelMode = LabelMode;
    mode: LabelMode = new ConfigLabelMode().default();

    form = new FormControl();
    isInputGroup = false;
    isInputGroupIcon = false;
    inputGroupMessage = '';
    inputGroupIcon = '';
    subscription: Subscription = new Subscription();
    errorMessage = '';
    isRequired = false;
    isError = false;
    minLangthError = 0;
    maxLengthError = 0;

    onChange = (value: string) => {
        return value;
    };
    onTouched = (value: string) => {
        return value;
    };
    ngOnInit() {
        if (window.location.href.includes("view")) {
            this.form.disable();
        }

        if (!this.type) {
            this.type = 'text';
        }

        this.subscription = this.form.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(() => {
                let value = this.form.value;
                if (typeof this.form.value === 'string') {
                    value = this.form.value.trim();
                }
                if (this.max && value > this.max) {
                    value = this.max;
                    setTimeout(() => {
                        this.form.patchValue(value, { emitEvent: false });
                    });
                }
                if (this.min && value < this.min) {
                    value = null;
                    this.form.patchValue(null);
                }
                this.onChange(value);
            });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    writeValue(value: any): void {
        this.form.setValue(value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        return isDisabled;
    }
}
