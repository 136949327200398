import { Component, ElementRef, HostListener } from '@angular/core';
import { UserService } from '@core/services/auth/user.service';
import { SweetAlert2Service } from '@core/services/sweet-alert/sweet-alert.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
    textEmail = '';
    textGroup = '';

    constructor(
        private elementRef: ElementRef,
        private keycloak: KeycloakService,
        private swal: SweetAlert2Service,
        private userService: UserService,
        private translate: TranslateService,
    ) {
        const user = this.userService.getUser();
        if (user) {
            this.textEmail = user.email;
            this.textGroup = user.group ?? '';
        }
    }
    isOpen = false;

    dropdownMenu = [
        {
            name: this.translate.instant('AUTH.LOGOUT.LOGOUT'),
            icon: 'fa-solid fa-right-from-bracket',
            path: 'logout',
        },
    ];

    toggleDropdown() {
        this.isOpen = !this.isOpen;
    }
    getDropdownIconClass() {
        return this.isOpen ? 'fa-solid fa-angle-up' : 'fa-solid fa-angle-down';
    }
    @HostListener('window:resize', ['$event'])
    onResize() {
        if (this.isOpen) {
            this.toggleDropdown();
        }
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: Event) {
        const targetElement = event.target as HTMLElement;
        if (!this.elementRef.nativeElement.contains(targetElement)) {
            this.isOpen = false;
        }
    }
    async navigateTo() {
        await this.swal.onLogOut().then(() => {
            this.userService.clearUser();
            this.keycloak.logout(environment.homeUrl);
        });
    }
}
