<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="inputContent"></ng-container>
</ng-container>

<ng-template #default>
  <div class="form-group validated">
    <label class="form-control-label" *ngIf="!disabledLabel" [for]="name">{{ label | translate }}</label>
    <ng-container [ngTemplateOutlet]="inputContent"></ng-container>
  </div>
</ng-template>

<ng-template #inline>
  <div class="form-group row validated">
    <label class="col-md-4 col-sm-12 input-inline-label form-control-label" [for]="name">{{ label | translate }}</label>
    <div class="col-md-8 col-sm-12">
      <ng-container [ngTemplateOutlet]="inputContent"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #inputContent>
  <div class="input-group validated">
    <input class="form-control" [matDatepicker]="picker" (click)="picker.open()" [formControl]="form"
      [placeholder]="placeholder" [ngClass]="{ 'is-invalid': isError }" [id]="name" [name]="name"
      (keydown)="onkeyDown($event)" autocomplete="off" [min]="disLastDate" [disabled]="isDisabled" />
    <div class="input-group-append">
      <ng-container [ngSwitch]="typeMode">
        <span class="input-group-text " (click)="picker.open()"
          [ngClass]="disabled? 'disabled-bg': 'non-disabled-bg' "><svg width="24" height="24" viewBox="0 0 24 24"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
              fill="#505050" />
          </svg>
        </span>

      </ng-container>
    </div>
    <mat-datepicker #picker></mat-datepicker>
    <div class="invalid-feedback">{{ errorMessage | translate }}</div>
  </div>
</ng-template>