import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from '@core/services/auth/user.service';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
    constructor(
        protected override readonly router: Router,
        protected readonly keycloak: KeycloakService,
        protected readonly userService: UserService,
        protected readonly jwthelp: JwtHelperService,

    ) {
        super(router, keycloak);
    }

    public async isAccessAllowed() {
        const token = localStorage.getItem("token")
        const isExpired = this.jwthelp.isTokenExpired(token)

        if (!isExpired) {
            return true;
        }
        else if (!this.authenticated) {
            console.error('User is not authenticated');
            this.userService.clearUser();
            this.router.navigate(['/auth']);
            return false;
        }

        return true;
    }
}
