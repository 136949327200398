import { NativeDateAdapter, MatDateFormats } from '@angular/material/core';
import { Injectable } from '@angular/core';

@Injectable()
export class AppDateYearAdapter extends NativeDateAdapter {
  monthsThMini = [
    'undefine',
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.',
  ];

  override format(date: Date, displayFormat: string): string {
    if (displayFormat === 'input') {
      const year = date.getFullYear();
      return `${year + 543}`;
    } else if (displayFormat === 'thai') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? month : month;
      const year = date.getFullYear();
      return `${day} ${this.monthsThMini[Number(month)]} ${year + 543}`;
    }

    return date.toDateString();
  }
}

export const APP_DATE_YEAR_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'input',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
