/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Input, OnDestroy, forwardRef, ViewEncapsulation } from '@angular/core';
import { LabelMode } from '../_enums/label-mode.enum';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ConfigLabelMode } from '../_config/label.config';
import { ErrorMessage } from '@modules/error-message';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class TextAreaComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  // @Input()
  // set error(value: string | undefined) {
  //   this.isError = false;
  //   this.errorMessage = '';

  //   if (value) {
  //     this.isError = true;
  //     this.errorMessage = new ErrorMessage().getErrorMessage(value);

  //   }
  // }

  @Input()
  set error(value: any) {
    this.isError = false;
    this.errorMessage = '';
    this.maxLengthError = 0;
    this.minLangthError = 0;

    if (value) {
      this.isError = true;
      this.errorMessage = new ErrorMessage().getErrorMessage(value);

      if (value.minlength) {
        this.minLangthError = value.minlength.requiredLength;
      } else if (value.maxlength) {
        this.maxLengthError = value.maxlength.requiredLength;
      }
      if (value.min) {
        this.minLangthError = value.min.min;
      } else if (value.max) {
        this.maxLengthError = value.max.max;
      }
    }
  }


  @Input() name = '';
  @Input() readonly = false;
  @Input() placeholder = '';
  @Input() rows = 3;
  @Input() label = '';
  @Input() inputLabel = '';
  @Input()
  set labelMode(value: LabelMode) {
    this.mode = value;
  }
  @Input() maxLength: number | null = null;

  LabelMode = LabelMode;
  mode: LabelMode = new ConfigLabelMode().default();


  form = new FormControl();
  subscription: Subscription = new Subscription;
  errorMessage = '';
  isRequired = false;
  isError = false;
  minLangthError = 0;
  maxLengthError = 0;

  onChange = (value: string) => {
    return value;
  };
  onTouched = (value: string) => {
    return value;
  };

  ngOnInit() {
    this.subscription = this.form.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      const value = this.form.value;
      this.onChange(value);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  writeValue(value: string): void {
    this.form.setValue(value);
  }

  registerOnChange(fn: () => string): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => string): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    return isDisabled;
  }
}
