import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { InputComponent } from './input/input.component';
import { InputFileComponent } from './input-file/input-file.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectComponent } from './select/select.component';
import { SelectSearchComponent } from './select-search/select-search.component';
import { SelectSearchThComponent } from './select-search-th/select-search-th.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DatatableComponent } from './datatable/datatable.component';
import { MatSortModule } from '@angular/material/sort';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { MatRadioModule } from '@angular/material/radio';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { TextAreaComponent } from './text-area/text-area.component';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgSelectModule } from '@ng-select/ng-select';
import {
    MatDialogModule,
    MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SelectThComponent } from './select-th/select-th.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { SelectMuliComponent } from './select-muli/select-muli.component';
import { MatIconModule } from '@angular/material/icon';
import { UploadImageCustomComponent } from './upload-image-custom/upload-image-custom.component';
import { DatepickerYearComponent } from './datepicker-year/datepicker-year.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { DatepickerComponent } from './datepicker/datepicker.component';
import {
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    // OWL_DATE_TIME_FORMATS,
    // OWL_DATE_TIME_LOCALE,
} from '@busacca/ng-pick-datetime';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PipesModule } from 'pipes/pipes.module';
import { SideBarComponent } from './side-bar/side-bar.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { DisplayDatePipe } from 'app/core/pipe/display-date.pipe';
import { DisplayRejectName } from '@core/pipe/display-reject-category';
import { DisplaySubRejectName } from '@core/pipe/display-reject-subcategory';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { TabButtonComponent } from './tab-button/tab-button.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ModalMessageComponent } from './modal-message/modal-message.component';
import { InputLogoImageComponent } from './input-logo-image/input-logo-image.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { InputImageMutiComponent } from './input-image-muti/input-image-muti.component';
import { InputPriceComponent } from './input-price/input-price.component';
import { InputMaxMinComponent } from './input-max-min/input-max-min.component';
import { InputPasswordComponent } from './input-password/input-password.component';
import { ModalUploadStockistProductComponent } from './modal-upload-stockist-product/modal-upload-stockist-product.component';
import { UtilService } from '@core/services/shared/util.service';
import { InputPercentComponent } from './input-percent/input-percent.component';
import { TabButtonModalComponent } from './tab-button-modal/tab-button-modal.component';
import { CustomDateAdapter } from 'app/_helpers/custom-datepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MultiSelectTreeComponent } from './multi-select-tree/multi-select-tree.component';
import { SelectMuliCategoryComponent } from './select-muli-category/select-muli-category.component';
import { InputImageFullScaleComponent } from './input-image-full-scale/input-image-full-scale.component';
import { SelectMuliStockistComponent } from './select-muti-stockist/select-muli-stockist.component';

export const MY_MOMENT_FORMATS = {
    parseInput: 'DD/MM/YYYY',
    fullPickerInput: 'DD/MM/YYYY',
    datePickerInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    timePickerInput: 'HH:mm',
};

const maskConfig: Partial<IConfig> = {
    validation: false,
};

@NgModule({
    declarations: [
        InputComponent,
        InputFileComponent,
        SelectComponent,
        SelectThComponent,
        SelectSearchComponent,
        SelectSearchThComponent,
        CheckboxComponent,
        DatatableComponent,
        RadioButtonComponent,
        TextAreaComponent,
        UploadImageComponent,
        SelectMuliComponent,
        UploadImageCustomComponent,
        DatepickerYearComponent,
        DatepickerComponent,
        SideBarComponent,
        NavBarComponent,
        DisplayDatePipe,
        DisplayRejectName,
        DisplaySubRejectName,
        ProgressBarComponent,
        TabButtonComponent,
        BreadcrumbComponent,
        ModalMessageComponent,
        InputLogoImageComponent,
        ToggleButtonComponent,
        InputImageMutiComponent,
        InputPriceComponent,
        InputMaxMinComponent,
        InputPasswordComponent,
        ModalUploadStockistProductComponent,
        InputPercentComponent,
        TabButtonModalComponent,
        MultiSelectTreeComponent,
        SelectMuliCategoryComponent,
        InputImageFullScaleComponent,
        SelectMuliStockistComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgSelectModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatRadioModule,
        NgxMaskModule.forRoot(maskConfig),
        PipesModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatIconModule,
        MatDatepickerModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        OwlMomentDateTimeModule,
        TranslateModule,
        MatProgressBarModule,
        MatNativeDateModule,
        DragDropModule
    ],
    exports: [
        InputComponent,
        InputFileComponent,
        CheckboxComponent,
        SelectComponent,
        SelectThComponent,
        SelectSearchComponent,
        SelectSearchThComponent,
        DatatableComponent,
        RadioButtonComponent,
        TextAreaComponent,
        UploadImageComponent,
        NgxPermissionsModule,
        SelectMuliComponent,
        UploadImageCustomComponent,
        DatepickerYearComponent,
        DatepickerComponent,
        SideBarComponent,
        NavBarComponent,
        DisplayDatePipe,
        DisplayRejectName,
        DisplaySubRejectName,
        ProgressBarComponent,
        TabButtonComponent,
        BreadcrumbComponent,
        InputLogoImageComponent,
        InputImageMutiComponent,
        InputPriceComponent,
        InputMaxMinComponent,
        InputPasswordComponent,
        InputPercentComponent,
        TabButtonModalComponent,
        MultiSelectTreeComponent,
        SelectMuliCategoryComponent,
        InputImageFullScaleComponent,
        SelectMuliStockistComponent
    ],
    providers: [
        // { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
        // { provide: OWL_DATE_TIME_LOCALE, useValue: 'th' },
        UtilService,
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: { hasBackdrop: true },
        },
        { provide: MAT_DATE_LOCALE, useValue: CustomDateAdapter },
        { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, TranslateService] },
        [DatePipe]
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
