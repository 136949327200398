import { UserService } from '@core/services/auth/user.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    constructor(
        private translate: TranslateService,
        private userService: UserService,
    ) {
        this.translate.setDefaultLang('th');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadTranslations(...args: any[]): void {
        args.forEach((locale) => {
            this.translate.setTranslation(locale.lang, locale.data, true);
        });
    }

    getLanguage(): string {
        const lang = this.userService.getUser()?.locale;
        return lang ? lang : 'th';
    }
}
