import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from './modules/shared/shared.module';
import { LayoutMainComponent } from './layout/layout-main/layout-main.component';
import { LoginComponent } from './modules/main/pages/auth/components/login/login.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { retailerGuard } from '@core/guards/retailer.guard';
import { stockistGuard } from '@core/guards/stockist.guard';
import { productGuard } from '@core/guards/product.guard';
import { adminGuard } from '@core/guards/admin.guard';
import { giftGuard } from '@core/guards/gift.guard';
import { cmsGuard } from '@core/guards/cms.guard';
import { couponGuard } from '@core/guards/coupon.guard';
import { billingGuard } from '@core/guards/billing.guard';
import { orderGuard } from '@core/guards/order.guard';
import { notificationGuard } from '@core/guards/notification.guard';
import { reportGuard } from '@core/guards/report.guard';

const routes: Routes = [
    {
        path: 'auth',
        component: LoginComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/main/pages/auth/auth.module').then(
                        (m) => m.AuthModule,
                    ),
            },
        ],
    },
    {
        path: 'retailer',
        component: LayoutMainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [retailerGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import(
                        './modules/main/pages/retailer/retailer.module'
                    ).then((m) => m.RetailerModule),
            },
        ],
    },
    {
        path: 'stockist',
        component: LayoutMainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [stockistGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import(
                        './modules/main/pages/stockist/stockist.module'
                    ).then((m) => m.StockistModule),
            },
        ],
    },
    {
        path: 'product',
        component: LayoutMainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [productGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/main/pages/product/product.module').then(
                        (m) => m.ProductModule,
                    ),
            },
        ],
    },
    {
        path: 'admin',
        component: LayoutMainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [adminGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/main/pages/admin/admin.module').then(
                        (m) => m.AdminModule,
                    ),
            },
        ],
    },
    {
        path: 'gift',
        component: LayoutMainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [giftGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/main/pages/gift/gift.module').then(
                        (m) => m.GiftModule,
                    ),
            },
        ],
    },
    {
        path: 'cms',
        component: LayoutMainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [cmsGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/main/pages/cms/cms.module').then(
                        (m) => m.CmsModule,
                    ),
            },
        ],
    },
    {
        path: 'coupon',
        component: LayoutMainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [couponGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/main/pages/coupon/coupon.module').then(
                        (m) => m.CouponModule,
                    ),
            },
        ],
    },
    {
        path: 'billing',
        component: LayoutMainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [billingGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/main/pages/billing/billing.module').then(
                        (m) => m.BillingModule,
                    ),
            },
        ],
    },
    {
        path: 'order',
        component: LayoutMainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [orderGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/main/pages/order/order.module').then(
                        (m) => m.OrderModule,
                    ),
            },
        ],
    },
    {
        path: 'notification',
        component: LayoutMainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [notificationGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import(
                        './modules/main/pages/notification/notification.module'
                    ).then((m) => m.NotificationModule),
            },
        ],
    },
    {
        path: 'report',
        component: LayoutMainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [reportGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./modules/main/pages/report/report.module').then(
                        (m) => m.ReportModule,
                    ),
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'auth',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes), SharedModule],
    exports: [RouterModule],
})
export class AppRoutingModule {}
