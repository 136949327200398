import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeTh from '@angular/common/locales/th';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { Interceptor } from '@core/interceptors/interceptor';
import { environment } from '@environments/environment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslationService } from '../app/modules/i18n/translation.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutMainComponent } from './layout/layout-main/layout-main.component';
import { SharedModule } from './modules/shared/shared.module';

registerLocaleData(localeTh, 'th');
const initialConfig = () => {
    const el = document.getElementById('web-config');
    if (el) {
        const config = el.innerText.trim();
        if (config) {
            environment.CONFIG = JSON.parse(config);
            for (const c in environment.CONFIG) {
                environment[c] = environment.CONFIG[c];
            }
        }
        el.remove();
    }
};

function initializeKeycloak(keycloak: KeycloakService) {
    return () =>
        keycloak.init({
            config: {
                url: environment.keycloakUrl,
                realm: environment.keycloakRealm,
                clientId: environment.keycloakClientId,
            },
            initOptions: {
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri:
                    window.location.origin + '/assets/silent-check-sso.html',
            },
        });
}

@NgModule({
    declarations: [AppComponent, LayoutMainComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot(),
        KeycloakAngularModule,
        NgxSpinnerModule,
        InfiniteScrollModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: APP_BASE_HREF,
            useFactory: initialConfig,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService],
        },
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        JwtHelperService,
        TranslationService,
        TranslateService
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(translate: TranslateService) {
        translate.addLangs(['en', 'th']);
        translate.setDefaultLang('en');
        const browserLang = translate.getBrowserLang() || 'en';
        translate.use(browserLang.match(/en|th/) ? browserLang : 'en');
    }
}
