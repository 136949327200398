import { Injectable } from "@angular/core";

@Injectable()
export class UtilService {
    convertFileSize(fileSize: number, decimal?: number) {
         if (fileSize / 1024 / 1024 > 0.01) {
            return (fileSize / 1024 / 1024).toFixed(decimal ?? 2) + 'MB';
        } else {
            return (fileSize / 1024).toFixed(decimal ?? 2) + 'KB';
        }
    }
}