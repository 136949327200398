import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Permission } from '@core/models/permission/permission.model';
import { UserService } from '@core/services/auth/user.service';
import { environment } from '@environments/environment';
import {
    Permission as PermissionENUM,
    PermissionModuleENUM,
} from '@utils/permission.enum';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    constructor(
        protected readonly router: Router,
        protected readonly userService: UserService,
        protected readonly keycloak: KeycloakService,
    ) { }

    clearUserAndRedirect() {
        this.userService.clearUser();
        this.router.navigate(['/auth']);
    }

    async authenFaild() {
        await this.keycloak.logout(environment.homeUrl);
        this.clearUserAndRedirect();
    }

    getPermissions(): Permission[] {
        const textDyanmic = 'DYNAMIC-ID';
        return [
            {
                order: 0,
                url: 'retailer',
                permissions: [PermissionENUM.VIEW_RETAILER_LIST],
                module: PermissionModuleENUM.RETAILER,
                isList: true,
            },
            {
                order: 0,
                url: 'retailer/create',
                permissions: [PermissionENUM.ADD_NEW_RETAILER],
                module: PermissionModuleENUM.RETAILER,
                isList: false,
            },
            {
                order: 0,
                url: 'retailer/update',
                permissions: [
                    PermissionENUM.EDIT_RETAILER_INFORMATION,
                    PermissionENUM.EDIT_RETAILER_PHONE_NUMBER,
                    PermissionENUM.UPDATE_RETAILER_STATUS,
                ],
                module: PermissionModuleENUM.RETAILER,
                isList: false,
            },
            {
                order: 0,
                url: `retailer/view/${textDyanmic}`,
                permissions: [PermissionENUM.VIEW_RETAILER_LIST],
                module: PermissionModuleENUM.RETAILER,
                isList: false,
            },
            {
                order: 1,
                permissions: [PermissionENUM.VIEW_STOCKIST_LIST],
                module: PermissionModuleENUM.STOCKIST,
                isList: false,
                url: 'stockist',
            },
            {
                order: 1,
                permissions: [PermissionENUM.ADD_NEW_STOCKIST],
                module: PermissionModuleENUM.STOCKIST,
                isList: false,
                url: 'stockist/create',
            },
            {
                order: 1,
                permissions: [PermissionENUM.VIEW_STOCKIST_LIST],
                module: PermissionModuleENUM.STOCKIST,
                isList: false,
                url: `stockist/view/${textDyanmic}`,
            },
            {
                order: 1,
                permissions: [PermissionENUM.EDIT_STOCKIST_INFORMATION],
                module: PermissionModuleENUM.STOCKIST,
                isList: false,
                url: `stockist/update/${textDyanmic}/store-detail`,
            },
            {
                order: 1,
                permissions: [PermissionENUM.EDIT_STOCKIST_INFORMATION],
                module: PermissionModuleENUM.STOCKIST,
                isList: false,
                url: `stockist/update/${textDyanmic}/store-doc`,
            },
            {
                order: 1,
                permissions: [PermissionENUM.EDIT_STOCKIST_INFORMATION],
                module: PermissionModuleENUM.STOCKIST,
                isList: false,
                url: `stockist/update/${textDyanmic}/zones`,
            },
            {
                order: 1,
                permissions: [PermissionENUM.EDIT_STOCKIST_INFORMATION],
                module: PermissionModuleENUM.STOCKIST,
                isList: false,
                url: `stockist/update/${textDyanmic}/shelf`,
            },
            {
                order: 1,
                permissions: [PermissionENUM.EDIT_STOCKIST_INFORMATION, PermissionENUM.VIEW_PROMOTION_LIST],
                module: PermissionModuleENUM.STOCKIST,
                isList: false,
                url: `stockist/update/${textDyanmic}/promotion`,
            },
            {
                order: 3,
                permissions: [PermissionENUM.VIEW_MANUFACTURER],
                module: PermissionModuleENUM.PRODUCT,
                isList: true,
                url: 'product/manufacturer',
            },
            {
                order: 3,
                permissions: [PermissionENUM.VIEW_MANUFACTURER],
                module: PermissionModuleENUM.PRODUCT,
                isList: false,
                url: `product/manufacturer/view/${textDyanmic}`,
            },
            {
                order: 3,
                permissions: [PermissionENUM.ADD_MANUFACTURER],
                module: PermissionModuleENUM.PRODUCT,
                isList: false,
                url: 'product/manufacturer/create',
            },
            {
                order: 3,
                permissions: [PermissionENUM.EDIT_MANUFACTURER],
                module: PermissionModuleENUM.PRODUCT,
                isList: false,
                url: `product/manufacturer/edit/${textDyanmic}`,
            },
            {
                order: 3,
                permissions: [PermissionENUM.VIEW_BRAND],
                module: PermissionModuleENUM.PRODUCT,
                isList: true,
                url: 'product/brand',
            },
            {
                order: 3,
                permissions: [PermissionENUM.VIEW_BRAND],
                module: PermissionModuleENUM.PRODUCT,
                isList: false,
                url: `product/brand/view/${textDyanmic}`,
            },
            {
                order: 3,
                permissions: [PermissionENUM.ADD_BRAND],
                module: PermissionModuleENUM.PRODUCT,
                isList: false,
                url: 'product/brand/create',
            },
            {
                order: 3,
                permissions: [PermissionENUM.EDIT_BRAND],
                module: PermissionModuleENUM.PRODUCT,
                isList: false,
                url: `product/brand/edit/${textDyanmic}`,
            },
            {
                order: 3,
                permissions: [PermissionENUM.VIEW_CATEGORY],
                module: PermissionModuleENUM.PRODUCT,
                isList: true,
                url: 'product/category',
            },
            {
                order: 3,
                permissions: [PermissionENUM.VIEW_PRODUCT_LIST],
                module: PermissionModuleENUM.PRODUCT,
                isList: true,
                url: 'product/products',
            },
            {
                order: 3,
                permissions: [PermissionENUM.VIEW_PRODUCT_LIST],
                module: PermissionModuleENUM.PRODUCT,
                isList: false,
                url: `product/products/view/${textDyanmic}`,
            },
            {
                order: 3,
                permissions: [PermissionENUM.ADD_PRODUCT],
                module: PermissionModuleENUM.PRODUCT,
                isList: false,
                url: 'product/products/create',
            },
            {
                order: 3,
                permissions: [PermissionENUM.EDIT_PRODUCT_LIST],
                module: PermissionModuleENUM.PRODUCT,
                isList: false,
                url: `product/products/edit/${textDyanmic}`,
            },
            {
                order: 3,
                permissions: [PermissionENUM.VIEW_PRODUCT_LIST],
                module: PermissionModuleENUM.PRODUCT,
                url: 'product/products/upload-history',
                isList: false,
            },
            {
                order: 3,
                permissions: [PermissionENUM.VIEW_PRODUCT_LIST],
                module: PermissionModuleENUM.PRODUCT,
                url: `product/products/upload-history/${textDyanmic}/detail`,
                isList: false,
            },
            {
                order: 3,
                permissions: [PermissionENUM.ADD_PRODUCT],
                module: PermissionModuleENUM.PRODUCT,
                url: 'product/products/upload-history/upload',
                isList: false,
            },
            {
                order: 6,
                url: 'admin',
                permissions: [PermissionENUM.VIEW_USER_LIST],
                module: PermissionModuleENUM.ADMIN,
                isList: true,
            },
            {
                order: 6,
                url: 'admin/create',
                permissions: [PermissionENUM.ADD_USER],
                module: PermissionModuleENUM.ADMIN,
                isList: false,
            },
            {
                order: 6,
                url: `admin/update/${textDyanmic}`,
                permissions: [PermissionENUM.UPDATE_USER_STATUS],
                module: PermissionModuleENUM.ADMIN,
                isList: false,
            },
            {
                order: 6,
                url: `admin/edit/${textDyanmic}`,
                permissions: [PermissionENUM.EDIT_USER_INFORMATION],
                module: PermissionModuleENUM.ADMIN,
                isList: false,
            },
            {
                order: 6,
                url: `admin/view/${textDyanmic}`,
                permissions: [PermissionENUM.VIEW_USER_LIST],
                module: PermissionModuleENUM.ADMIN,
                isList: false,
            },
            {
                order: 5,
                url: 'gift',
                permissions: [PermissionENUM.VIEW_GIFT],
                module: PermissionModuleENUM.GIFT,
                isList: true,
            },
            {
                order: 5,
                url: 'gift/create',
                permissions: [PermissionENUM.CREATE_GIFT],
                module: PermissionModuleENUM.GIFT,
                isList: false,
            },
            {
                order: 5,
                url: `gift/edit/${textDyanmic}`,
                permissions: [PermissionENUM.EDIT_GIFT],
                module: PermissionModuleENUM.GIFT,
                isList: false,
            },
            {
                order: 5,
                url: `gift/view/${textDyanmic}`,
                permissions: [PermissionENUM.VIEW_GIFT],
                module: PermissionModuleENUM.GIFT,
                isList: false,
            },
            {
                order: 8,
                url: 'cms',
                permissions: [
                    PermissionENUM.VIEW_BANNER_LIST,
                    PermissionENUM.VIEW_PRODUCT_SHOWCASE_LIST,
                ],
                module: PermissionModuleENUM.CMS,
                isList: true,
            },
            {
                order: 8,
                url: 'cms/create/show-case',
                permissions: [PermissionENUM.ADD_PRODUCT_SHOWCASE_LIST],
                module: PermissionModuleENUM.CMS,
                isList: false,
            },
            {
                order: 8,
                url: `cms/edit/show-case/${textDyanmic}`,
                permissions: [PermissionENUM.EDIT_PRODUCT_SHOWCASE_LIST],
                module: PermissionModuleENUM.CMS,
                isList: false,
            },
            {
                order: 8,
                url: `cms/view/show-case/${textDyanmic}`,
                permissions: [PermissionENUM.VIEW_PRODUCT_SHOWCASE_LIST, PermissionENUM.EDIT_PRODUCT_SHOWCASE_LIST],
                module: PermissionModuleENUM.CMS,
                isList: false,
            },

            {
                order: 8,
                url: `cms/view/banner/${textDyanmic}`,
                permissions: [PermissionENUM.VIEW_BANNER_LIST, PermissionENUM.EDIT_BANNER],
                module: PermissionModuleENUM.CMS,
                isList: false,
            },
            {
                order: 8,
                url: 'cms/create/banner',
                permissions: [PermissionENUM.ADD_BANNER],
                module: PermissionModuleENUM.CMS,
                isList: false,
            },
            {
                order: 8,
                url: `cms/edit/banner/${textDyanmic}`,
                permissions: [PermissionENUM.EDIT_BANNER],
                module: PermissionModuleENUM.CMS,
                isList: false,
            },
            {
                order: 4,
                url: 'coupon',
                permissions: [PermissionENUM.VIEW_COUPON_LIST],
                module: PermissionModuleENUM.COUPON,
                isList: true,
            },
            {
                order: 4,
                url: 'coupon/create',
                permissions: [PermissionENUM.CREATE_COUPON],
                module: PermissionModuleENUM.COUPON,
                isList: false,
            },
            {
                order: 4,
                url: `coupon/edit/${textDyanmic}`,
                permissions: [PermissionENUM.EDIT_COUPON],
                module: PermissionModuleENUM.COUPON,
                isList: false,
            },
            {
                order: 4,
                url: `coupon/view/${textDyanmic}`,
                permissions: [PermissionENUM.VIEW_COUPON_LIST],
                module: PermissionModuleENUM.COUPON,
                isList: false,
            },
            {
                order: 7,
                url: 'billing',
                permissions: [PermissionENUM.VIEW_BILLING],
                module: PermissionModuleENUM.BILLING,
                isList: true,
            },
            {
                order: 7,
                url: 'billing/billing-stockist/:id',
                permissions: [PermissionENUM.VIEW_BILLING],
                module: PermissionModuleENUM.BILLING,
                isList: false,
            },
            {
                order: 7,
                url: `billing/billing-stockist/${textDyanmic}`,
                permissions: [PermissionENUM.VIEW_BILLING],
                module: PermissionModuleENUM.BILLING,
                isList: false,
            },
            {
                order: 7,
                url: `billing/billing-stockist-detail/${textDyanmic}`,
                permissions: [PermissionENUM.VIEW_BILLING],
                module: PermissionModuleENUM.BILLING,
                isList: false,
            },
            {
                order: 2,
                url: 'order',
                permissions: [PermissionENUM.VIEW_ORDER],
                module: PermissionModuleENUM.ORDER,
                isList: true,
            },
            {
                order: 2,
                url: `order/${textDyanmic}`,
                permissions: [PermissionENUM.VIEW_ORDER],
                module: PermissionModuleENUM.ORDER,
                isList: false,
            },
            {
                order: 9,
                url: 'notification',
                permissions: [PermissionENUM.VIEW_NOTIFICATION_LIST],
                module: PermissionModuleENUM.NOTIFICATION,
                isList: true,
            },
            {
                order: 9,
                url: 'notification/create',
                permissions: [PermissionENUM.CREATE_NOTIFICATION],
                module: PermissionModuleENUM.NOTIFICATION,
                isList: false,
            },
            {
                order: 9,
                url: `notification/edit/${textDyanmic}`,
                permissions: [PermissionENUM.EDIT_NOTIFICATION],
                module: PermissionModuleENUM.NOTIFICATION,
                isList: false,
            },
            {
                order: 9,
                url: `notification/view/${textDyanmic}`,
                permissions: [PermissionENUM.VIEW_NOTIFICATION_LIST],
                module: PermissionModuleENUM.NOTIFICATION,
                isList: false,
            },
            {
                order: 10,
                url: 'report',
                permissions: [PermissionENUM.VIEW_REPORT],
                module: PermissionModuleENUM.REPORT,
                isList: true,
            },
        ];
    }

    getPermissionWithkey(
        permission: PermissionENUM,
        isListOnly?: boolean,
    ): Permission | null {
        const filter = this.getPermissions().filter((item) => {
            if (isListOnly) {
                return (
                    item.permissions.includes(permission) &&
                    item.isList == isListOnly
                );
            } else {
                return item.permissions.includes(permission);
            }
        });
        return filter.length > 0 ? filter[0] : null;
    }

    async permissionFaild() {
        console.error(
            'User does not have the necessary permissions to access this page',
        );
        await this.authenFaild();
    }

    async checkPermission(
        url: string,
        module: PermissionModuleENUM,
    ): Promise<boolean> {
        const permissions = this.getPermissions().filter((item) => {
            const tempURL = item.url.split('/');
            const tempStateURL = url
                .replaceAll(/\d+/g, 'DYNAMIC-ID')
                .replaceAll('/', '');
            const tempItemURL = item.url
                .replaceAll(/\d+/g, 'DYNAMIC-ID')
                .replaceAll('/', '');

            const isMatchingModule = item.module === module;
            if (tempURL.length === 1 || tempURL[0] === 'product') {
                return isMatchingModule && tempStateURL === tempItemURL;
            }
            return isMatchingModule && tempStateURL.includes(tempItemURL);
        });

        // console.log('permissions', permissions);
        // console.log('url', url);

        let isValid = false;
        if (permissions.length > 0) {
            const tempPermissions = permissions[0].permissions;

            this.userService.getRoles().forEach((role) => {
                if (this.userService.checkPermission(tempPermissions, role)) {
                    isValid = true;
                    return;
                }
            });
        }

        // console.log('isValid', isValid);
        if (!isValid) await this.permissionFaild();
        return isValid;
    }
}
